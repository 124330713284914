<mat-toolbar>
  <span class="example-spacer"></span>
  <button mat-button type="button" (click)="onCloseClick($event)">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<h2>
  {{ title }}
</h2>

<mat-toolbar>
  <button mat-button type="button" (click)="onConfirmClick($event)">
    <mat-icon>check</mat-icon>
    <span>Confirmar</span>
  </button>
  <button mat-button type="button" (click)="onCancelClick($event)">
    <mat-icon>cancel</mat-icon>
    <span>Cancelar</span>
  </button>
</mat-toolbar>
