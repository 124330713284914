import { EnSortBy, EnViewMode, GlobalService } from '@medlogic/shared/shared-interfaces';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import {
  loadApp,
  setSearch,
  successLoadApp,
  failLoadApp,
  setViewMode,
  setIsLoading,
  setIsMobile,
  detectIsMobile,
} from './medlogic.actions';

export const initialMedlogicState: IAppMedlogicState = {
  title: 'MEDLOGIC',
  isLoading: false,
  isMobile: true,
  enViewMode: EnViewMode.browse,
  search: '',
  showInactivated: false,
  enSortBy: EnSortBy.Name,
  person: null,
  process: null,
  generic: null,
  tenant: null,
  router: null,
  splashes: null,
  appMedlogic: null,
  login: null,
  exame: null,
  exameResultado: null,
  frailty: null,
  patient: null,
  evolutions: null,
  medication: null,
  stock: null,
  loginPWA: null,
  vitalSign: null,
  queue: null,
  ivcf: null,
  prescription: null,
  horarios: null,
  tutorial: null,
  isLoadingStack: []
};

const glb = new GlobalService();

const medlogicReducerObj = createReducer(
  initialMedlogicState,
  on(loadApp, (state, payload) => ({
    ...state,
    title: payload?.title,
    isLoading: payload?.isLoading,
    enViewMode: payload?.enViewMode,
  })),
  on(setSearch, (state: IAppMedlogicState, payload) => ({
    ...state,
    search: payload?.search,
    showInactivated: payload?.showInactivated !== undefined ? payload.showInactivated : state?.showInactivated,
    enSortBy: payload?.enSortBy !== undefined ? payload.enSortBy : state.enSortBy,
  })),
  on(setViewMode, (state: IAppMedlogicState, payload) => ({
    ...state,
    enViewMode: payload?.enViewMode,
  })),
  on(successLoadApp, (state: IAppMedlogicState, payload) => ({
    ...state,
    splashes: payload?.splashes,
  })),
  on(failLoadApp, (state: IAppMedlogicState, payload) => ({
    ...state,
    splashes: null,
  })),
  on(setIsLoading, (state: IAppMedlogicState, payload) => { // Se múltiplos isLoading = true forem chamados, será necessário chamar o mesmo número de vezes = false
    let isLoading = payload?.isLoading;
    let isLoadingStack = [];
    if (payload?.isLoading) {
      isLoadingStack = [...state?.isLoadingStack, payload?.isLoading];
    } else {
      isLoadingStack = [...state?.isLoadingStack];
      isLoadingStack.pop();
      isLoading = isLoadingStack?.pop() || false;
    }
    return ({
      ...state,
      isLoading,
      isLoadingStack
    });
  }),
  on(setIsMobile, (state: IAppMedlogicState, payload) => {
    return ({
      ...state,
      isMobile: payload?.isMobile,
    });
  }),
  on(detectIsMobile, (state: IAppMedlogicState, payload) => {
    return ({
      ...state,
      isMobile: glb.isMobile()
    });
  })
);

export function medlogicReducer(
  state: IAppMedlogicState | undefined,
  action: Action
) {
  return medlogicReducerObj(state, action);
}
