import { addLog } from '@medlogic/shared/state-log';
import { UiDialogAlertComponent } from '@medlogic/shared/ui/dialog/ui-dialog-alert';
import { MatDialog } from '@angular/material/dialog';
import { IAppMedlogicState, IPerson } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { PrescriptionMedicationCustomService, IntervencoesMedicamentosService } from '@medlogic/medlogic/medlogic-data-access';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  PrescriptionActionTypes,
  saveSuccessPrescription,
  saveFailPrescription,
} from './prescription.actions';
import {
  EnMaterialIcon,
  GlobalService, IInterventionMedication,
  ILog,
  IMedication, IPrescriptionMedication
} from '@medlogic/shared/shared-interfaces';
import { withLatestFrom } from 'rxjs/operators';
import { checkMedicationStock } from '../../state-stock/+state/stock.actions';
import { Dictionary } from '@ngrx/entity';
import { setMedicationsWasChanged } from '../../state-medication/+state/medication.actions';

@Injectable()
export class PrescriptionEffects {

  constructor(
    private glb: GlobalService,
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private prescriptionMedicationSrv: PrescriptionMedicationCustomService,
    private intervMedSrv: IntervencoesMedicamentosService,
    private matDialog: MatDialog
  ) { }

  // getPrescriptionByDate$ = createEffect(() => this.actions$
  //   .pipe(
  //     ofType<any>(PrescriptionActionTypes.GetByDate),
  //     withLatestFrom(this.store),
  //     mergeMap(([action, state]) => {
  //       const endDate = this.glb.addDays(action.dtStart, action.numOfDays);
  //       return this.prescriptionService.getAll(state.tenant.selectedTenant.prescriptionAno, action.dtStart, endDate).pipe(toArray());
  //     }),
  //     map((allPrescriptions) => {
  //       return fetchSuccessPrescription({ allPrescriptions: [...allPrescriptions] });
  //     }),
  //     catchError((e) => {
  //       console.log(e);
  //       return of(fetchErrorPrescription());
  //     })
  //   )
  // );

  /* Esse é o ponto de partida do processo de prescrição. É utilizado tanto para nova, quanto para salvar.
  * 1) Salva os dados da prescrição no PRESCRIÇÃO, tanto os dados, quanto o xml do grid com a lista de medicamentos.
  * 1.1) Nesse momento o INTERVENÇÕES MEDICAMENTOS ainda não é verificado e/ou registrado.
  * O Estoque de Materiais é quem gera o Id_Medicamento
  * Checar se o item já existe pelo nome, centro de custo, dosagem no Estoque de Materiais
  * Se não existir, criar
  * De qualquer modo, retornar o Id_Medicamento e salvar a Prescrição novamente, dessa vez corrigindo o Id
  * Depois, já com Id da Prescricao e do Medicamento, salvar no Intervenção Medicamento
  * Por último, acrescentar em Itens de Estoque caso não exista
  * 2) Dispara o checkMedicationStock para verificações e cadastro de estoque e intervenção.
  * */
  savePrescription$ = createEffect(() => this.actions$
    .pipe(
      ofType(PrescriptionActionTypes.SavePrescription),
      mergeMap((action: any) => {
        return of(action).pipe(
          withLatestFrom(this.store),
          mergeMap(([never, state]) => {
            const prescription = this.mapToPrescription(state);
            return this.prescriptionMedicationSrv.save<IPrescriptionMedication>(
              state?.tenant?.selectedTenant?.cadPrescriptionNo,
              prescription,
              state?.tenant?.login?.usuarioLogadoNo,
              state?.prescription?.selectedPrescription?.ocorrenciaNo
            );
          }),
          switchMap((prescription: IPrescriptionMedication) => {
            return [
              ...(
                !!prescription ?
                  [
                    addLog({ log: { msg: `Prescrição Salva - id: ${prescription?.ocorrenciaNo}`, showMessage: true } as ILog }),
                    saveSuccessPrescription({ prescription: { ...prescription, isSaving: true }, showMessage: false }),
                    checkMedicationStock({ medications: prescription.lstmedicationGrid })
                  ] :
                  [
                    addLog({ log: { msg: `Falha ao salvar a prescrição - id: ${prescription?.ocorrenciaNo}`, showMessage: true } as ILog }),
                    saveFailPrescription({ prescription: { ...prescription, isSaving: true } })
                  ]
              ),

            ];
          }),
          catchError((e: any) => {
            console.log(e);
            return of(saveFailPrescription(null));
          }),
        )
      })
    )
  );

  showMessage$ = (messageHtml: string) => tap((res: any) => { // prescription: any
    if (res?.showMessage) {
      const width = '380px';
      const height = '250px';
      this.matDialog
        .open(UiDialogAlertComponent, {
          width,
          height,
          id: '',
          minWidth: width,
          maxWidth: width,
          minHeight: height,
          maxHeight: height,
          data: {
            title: '  ',
            messageHtml,
            icon: EnMaterialIcon.check,
            btnOK: 'OK'
          }
        });
    }
  });

  /** Ainda faltam vários salvamentos não dá para exibir mensagem de sucesso. */
  saveSuccessPrescription$ = createEffect(() => this.actions$
    .pipe(
      ofType(PrescriptionActionTypes.SaveSucessPrescription),
      this.showMessage$('Prescrição salva com sucesso!'),
    ), { dispatch: false }
  );

  saveFailPrescription$ = createEffect(() => this.actions$
    .pipe(
      ofType(PrescriptionActionTypes.SaveFailPrescription),
      this.showMessage$('Houve falha na tentativa de salvar a prescrição!'),
    ), { dispatch: false }
  );


  updatePrescriptionExecutionCounter = 0;

  updatePrescription$ = createEffect(() => this.actions$
    .pipe(
      ofType(PrescriptionActionTypes.UpdatePrescription),
      mergeMap((action: any) => {
        return of(action)
          .pipe(
            withLatestFrom(this.store),
            mergeMap(([never, state]) => {
              const changedMedicationCount = Object.keys(state?.medication?.entities).filter(key => state?.medication?.entities[key].wasChanged)?.length * 2;
              const isLastMedicationCall = this.updatePrescriptionExecutionCounter >= (changedMedicationCount - 1);
              if (isLastMedicationCall) {
                const medication = action?.medication;
                const lstmedication = state?.prescription?.selectedPrescription?.lstmedicationGrid;
                const lstmedicationGrid = this.mapToIntervetionMedicationUpdate(lstmedication, medication);
                const prescription = { ...state?.prescription?.selectedPrescription, lstmedicationGrid } as IPrescriptionMedication;
                this.updatePrescriptionExecutionCounter = 0;
                return this.prescriptionMedicationSrv.save<IPrescriptionMedication>(
                  state?.tenant?.selectedTenant?.cadPrescriptionNo,
                  prescription,
                  state?.tenant?.login?.usuarioLogadoNo,
                  prescription.ocorrenciaNo
                );
              } else {
                this.updatePrescriptionExecutionCounter++;
                return EMPTY;
              }
            }),
            switchMap((prescription: IPrescriptionMedication) => {
              return [
                ...!!prescription ?
                  [
                    saveSuccessPrescription({ prescription, showMessage: true }),
                    setMedicationsWasChanged({ wasChanged: false }),
                    addLog({ log: { msg: `Prescrição atualizada com sucesso: ${prescription.ocorrenciaNo}`, showMessage: true } as ILog }),
                  ] :
                  [
                    saveFailPrescription({ prescription }),
                    addLog({ log: { msg: `Falha na atualização da prescrição: ${prescription.ocorrenciaNo}`, showMessage: true } as ILog }),
                  ]
              ];
            }),
            catchError((e: any) => {
              console.log(e);
              return of(saveFailPrescription(null));
            }),
          );
      })
    )
  );

  /** Atualiza todas as medicações, salvando a prescrição. */
  updatePrescriptionMedications$ = createEffect(() => this.actions$
    .pipe(
      ofType(PrescriptionActionTypes.UpdatePrescriptionMedications),
      mergeMap((action: any) => {
        return of(action)
          .pipe(
            withLatestFrom(this.store),
            mergeMap(([never, state]) => {
              const lstmedicationGrid = action?.medications;
              const prescription = { ...state?.prescription?.selectedPrescription, lstmedicationGrid } as IPrescriptionMedication;
              return this.prescriptionMedicationSrv.save<IPrescriptionMedication>(
                state?.tenant?.selectedTenant?.cadPrescriptionNo,
                prescription,
                state?.tenant?.login?.usuarioLogadoNo,
                prescription.ocorrenciaNo
              );
            }),
            switchMap((prescription: IPrescriptionMedication) => {
              return [
                !!prescription ?
                  saveSuccessPrescription({ prescription, showMessage: false }) :
                  saveFailPrescription({ prescription }),
              ];
            }),
            catchError((e: any) => {
              console.log(e);
              return of(saveFailPrescription(null));
            }),
          );
      })
    )
  );

  deletePrescriptionMedication$ = createEffect(() => this.actions$
    .pipe(
      ofType(PrescriptionActionTypes.DeletePrescriptionMedication),
      mergeMap((action: any) => {
        return of(action)
          .pipe(
            withLatestFrom(this.store),
            mergeMap(([never, state]) => {
              const lstmedicationGrid = this.mapToIntervetionMedication(state?.person?.person, state?.medication?.entities, state?.login?.userName);
              const idxToDelete = lstmedicationGrid?.findIndex(f => +f.ocorrenciaNo === +action?.ono);
              if (idxToDelete > 0) {
                lstmedicationGrid?.splice(idxToDelete, 1);
              }
              const prescription = { ...state?.prescription?.selectedPrescription, lstmedicationGrid } as IPrescriptionMedication;
              return this.prescriptionMedicationSrv.save<IPrescriptionMedication>(
                state?.tenant?.selectedTenant?.cadPrescriptionNo,
                prescription,
                state?.tenant?.login?.usuarioLogadoNo,
                prescription.ocorrenciaNo
              );
            }),
            switchMap((prescription: IPrescriptionMedication) => {
              return [
                ...!!prescription ?
                  [
                    saveSuccessPrescription({ prescription, showMessage: false }),
                    addLog({ log: { msg: `Medicamento removido da Prescrição: ${prescription.ocorrenciaNo}`, showMessage: true } as ILog })
                  ] :
                  [saveFailPrescription({ prescription })],
              ];
            }),
            catchError((e: any) => {
              console.log(e);
              return of(saveFailPrescription(null));
            }),
          );
      })
    )
  );

  private mapToPrescription(state: IAppMedlogicState): IPrescriptionMedication {
    try {
      const { nomeResidente, dataAniversario, nomemae, prontuario, centroCusto } = state?.person?.person;
      const prescription = {
        ...state?.prescription?.selectedPrescription,
        residente: nomeResidente,
        dataNascimento: dataAniversario,
        nomemae,
        identificacao1: `${nomeResidente}_${nomemae}`,
        identificacao2: `${dataAniversario}_${prontuario}`,
        centroCusto: centroCusto ? centroCusto : nomeResidente,
        prontuario,
        idPaciente: prontuario,
        // ocorrenciaNo:
        // codigo32935:
        titulo: `${prontuario}__${nomeResidente}__${nomemae}_${dataAniversario}`,
        habilitado: true,
        nascimento: dataAniversario,
        codHospedeTelaPrescricao: prontuario,
        codNome: `${prontuario}_${nomeResidente}`,
        codigoHospede: prontuario,
        codigoNome: `${prontuario}_${nomeResidente}`,
        responsavelNome: state?.login?.userName,
        // responsavelRG
        codHospede: prontuario,
        lstmedicationGrid: this.mapToIntervetionMedication(state?.person?.person, state?.medication?.entities, state?.login?.userName),
      } as IPrescriptionMedication;
      return prescription;
    } catch (error) {
      console.log(this.constructor.name, 'mapToPrescription', error.message);
    }
    return null;
  }

  mapToIntervetionMedication(person: IPerson, medications: Dictionary<IMedication>, professional: string): IInterventionMedication[] {
    const lstInterventions: IInterventionMedication[] = [];
    for (const key in medications) {
      if (key) {
        const intervention = this.intervMedSrv.mapMedicationToInverv(medications[key], professional);
        // const medication = {
        //   ocorrenciaNo: medications[key]?.ocorrenciaNo,
        //   guid: medications[key]?.guid,
        //   medicamento: medications[key]?.medicationName,
        //   tempoUso: medications[key]?.tempoUso,
        //   dosagem: medications[key]?.dosage,
        //   via: medications[key]?.access,
        //   orientacoes: medications[key]?.instruction,
        //   posologia: medications[key]?.enPosology,
        //   centrocusto: medications[key]?.costCenter,
        //   codigoPaciente: person?.prontuario,
        //   apresentacao: medications[key]?.presentation,
        //   quantUtilizadaD: medications[key]?.dailyQuantity,
        //   quantUtilizadaGota: medications[key]?.dailyQuantityGotas,
        //   hojedia: new Date().getDate().toString(),
        //   codigo: medications[key]?.ocorrenciaNo,
        //   titulo: `Paciente: ${person?.nomeResidente}__${medications[key]?.medicationName}__${medications[key]?.dosage}__${medications[key]?.medicationId}`,
        //   habilitado: true,
        //   // codigomedicamento: medications[key].medicationId,
        //   cascataCheckIDClienteIDMedicamentoDataEVOLUCAO: null,
        //   centroCustoIDCentroCusto: `${medications[key]?.costCenter}_${person?.prontuario}`,
        //   tIPOMedicamentos: medications[key].material,
        //   tipomedicamento: medications[key].material,
        //   dia: null,
        //   codigoPacienteNomePaciente: `${person?.prontuario}_${person?.nomeResidente}`,
        //   tIPOMaterial: medications[key].material,
        //   uNIDADEDENEGOCIO: medications[key]?.businessUnit,
        //   validCadastro: null,
        //   codPacienteNomedoPacienteCodMedicamento: medications[key]?.codPacienteNomedoPacienteCodMedicamento,
        //   dataPRESCRICAO: this.glb.dateToddMMYYYYhhmmss(new Date()),
        //   tipoMedicamentosCodPaciente: `${medications[key].material}__${person.prontuario}`, // estava fixo como 9
        //   medicametnoPrescrito: medications[key]?.medicationName,
        //   horaprimeiraDose: medications[key]?.prescribedTime,
        //   codigoMedicamentoPaciente: `${medications[key]?.medicationId}_${person?.nomeResidente}`,
        //   dataEVOLUCAO: new Date(),
        //   lstmedicationCheckin: medications[key]?.lstCheckin, // ATENÇÃO: Null poderia prejudicar os checkins existentes em caso de edição
        //   Id_Paciente: person?.prontuario,
        //   Id_Medicamento: medications[key]?.medicationId?.toString(),
        //   IdMedicamento_IdPaciente: `${medications[key]?.medicationId}_${person?.prontuario}`,
        //   interroperMedicacao: medications[key]?.stopMedication,
        //   interrupcaoMotivo: medications[key]?.stopInstructions,
        //   calc__isSOS: null,
        //   intervaloHoras: null,
        //   horarios: medications[key]?.horarios,
        //   lsthorariosGrid: this.mapToIntervencaoHorarios(person, medications[key]?.horarios),
        //   dataInicio: this.glb.dateToddMMYYYYhhmmss(medications[key]?.dtStart),
        //   dataFim: this.glb.dateToddMMYYYYhhmmss(medications[key]?.dtEnd),
        //   medicamentoControlado: medications[key]?.controlado,
        //   profissional: professional,
        //   tempoEstimado: null,
        //   quantTempoEstimado: null,
        //   codigoHospede: person?.prontuario,
        //   intervaloDS: medications[key]?.intervaloDS,
        //   Id_Prescricao: medications[key]?.prescriptionId,
        //   ordem: medications[key]?.order
        // } as IInterventionMedication
        lstInterventions.push(intervention);
      }
    }
    return lstInterventions;
  }

  mapToIntervetionMedicationUpdate(lstMedication: IInterventionMedication[], medication: IInterventionMedication): IInterventionMedication[] {
    const lstMedicamentos: IInterventionMedication[] = [];
    lstMedication.map(item => {
      if (this.glb.isEqual(`${item.medicamento}_${item.dosagem}`, `${medication.medicamento}_${medication.dosagem}`)) {
        item = medication;
      }
      lstMedicamentos.push(item);
    })
    return lstMedicamentos;
  }


}
