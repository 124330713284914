import { Component, OnInit, Input } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { EnTheme } from '@medlogic/shared/shared-interfaces';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { BehaviorSubject } from 'rxjs';

export interface IBluePrintItem {
  isBlack: boolean;
}
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-ge-blueprint',
  templateUrl: './blueprint.component.html',
  styleUrls: ['./blueprint.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 0.8 })),
      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300)
      ]),
      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(7000, style({ opacity: 0 })))
    ])
  ]
})
export class BlueprintComponent implements OnInit {

  private _isLoading: boolean;
  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input()
  public set isLoading(v: boolean) {
    this._isLoading = v;
    this.isLoadingSmooth = true;
    setTimeout(
      () => this.isLoadingSmooth = false,
      2000
    );
  }

  @Input() module: string;

  private _enTheme = EnTheme.default;
  public get enTheme(): EnTheme {
    return this._enTheme;
  }
  @Input()
  public set enTheme(v: EnTheme) {
    this._enTheme = v;
    this.ngOnInit();
  }

  @Input() isMobile: boolean;

  items$ = new BehaviorSubject<IBluePrintItem[]>(null);
  isLoadingSmooth = false;
  isFadeOut = false;

  ENTHEME = EnTheme;

  constructor(
    private log: LogService
  ) { }

  ngOnInit() {
    this.items$.next(this.generateItems(10, this.enTheme === EnTheme.black));
  }

  generateItems(numOfItems: number, isBlack: boolean): IBluePrintItem[] {
    try {
      const items = [];
      for (let i = 0; i < numOfItems; i++) {
        items.push({ isBlack });
      }
      return items;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'generateItems', error.message);
    }
    return null;
  }

  // /** Verifica se o tema black deve ser aplicado. */
  // isBlack(): boolean {
  //   try {
  //     return this.enTheme === EnTheme.black;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'isBlack', error.message);
  //   }
  //   return false;
  // }

}
