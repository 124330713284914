import { UiDialogAlertModule } from '@medlogic/shared/ui/dialog/ui-dialog-alert';
import { UiDialogConfirmModule } from '@medlogic/shared/ui/dialog/ui-dialog-confirm';
import { DataAccessModule } from '@medlogic/shared/shared-data-access';
import { MaterialModule } from '@medlogic/shared/material';
import { UiLoadingModule } from '@medlogic/shared/ui/ui-loading';

// OS SERVIÇOS QUE JÁ FAZEM PARTE DE OUTROS MÓDULOS NÃO DEVEM SER REDECLARADOS.
// AO INVÉS, IMPORTE OS RESPECTIVOS MÓDULOS
// import { GlobalService, SharedInterfacesNgModule } from '@medlogic/shared/shared-interfaces';
// import { LogService } from '@medlogic/shared/shared-interfaces';
// import { ConfigStateService } from '@medlogic/shared/state-config';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MinZoomDirective } from './directive/minZoom.directive';
// import { WsTrackerService } from './service/ws-tracker.service';
import { TarefaService } from './service/tarefa.service';
// import { PdfGeneratorService } from './service/pdf-generator.service';
import { IFrameDialogComponent } from './component/iframe-dialog/iframe-dialog.component';
import { SafePipe } from './pipe/safe.pipe';
import { TasksService } from './service/tasks.service';
import { OcorrenciaService } from './service/ocorrencia.service';
import { UserService } from './service/user.service';
import { CustomizeService } from './service/customize.service';
import { ActivityService } from './service/activity.service';
import { WindowDialogComponent } from './component/window-dialog/window-dialog.component';
import { DecisionDialogComponent } from './component/decision-dialog/decision-dialog.component';
import { PeriodDialogComponent } from './component/period-dialog/period-dialog.component';
import { PaperDialogComponent } from './component/paper-dialog/paper-dialog.component';
// import { LoginComponent } from './component/login/login.component';
import { ChartsModule } from 'ng2-charts';
import { NumberPtbrPipe } from './pipe/number-ptbr.pipe';
// import { LoginService } from './service/login-service';
import { CadConfigService } from './service/cad-config.service';
import { DateFormat } from './directive/date-format';
import { CustomerConfigService } from './service/customer-config.service';
// import { CookieService } from 'ngx-cookie-service';
// import { NgSpinKitModule } from 'ng-spin-kit';
import { BaseService } from './service/base.service';
import { FormDALService } from './service/form-dal.service';
import { MaterialDialogService } from './service/material-dialog.service';
import { NavigationService } from './service/navigation.service';
import { BottomSheetComponent } from './component/bottom-sheet/bottom-sheet.component';
import { RouterModule } from '@angular/router';

// import { LyResizingCroppingImageModule } from '@alyle/ui/resizing-cropping-images';
// import { LyButtonModule } from '@alyle/ui/button';
// import { LyIconModule } from '@alyle/ui/icon';
const ENTRY_COMPONENTS = [
  // Component
  IFrameDialogComponent,
  WindowDialogComponent,
  PaperDialogComponent,
  DecisionDialogComponent,
  PeriodDialogComponent,
  BottomSheetComponent,
];

const COMPONENTS = [
  // Directive
  DateFormat,
  MinZoomDirective,
  // Pipe
  NumberPtbrPipe,
  SafePipe,
  // LoginComponent,
];

const MODULES = [
  // Module
  CommonModule,
  RouterModule,
  // BrowserAnimationsModule,
  ReactiveFormsModule,
  FormsModule, // O reactiveforms deve ser usado como padrão. No entanto,
  ChartsModule,
  UiLoadingModule,
  MaterialModule,
  DataAccessModule,
  UiDialogConfirmModule,
  UiDialogAlertModule,
  // SharedInterfacesNgModule
];

@NgModule({
  declarations: [
    COMPONENTS, ENTRY_COMPONENTS
  ],
  imports: [
    // Module
    MODULES
    // NgSpinKitModule,
    // às vezes há controles avulsos, não mapeados a modelos, que o uso de forms pode fazer sentido (como filtros.)
    // LyResizingCroppingImageModule,
    // LyButtonModule,
    // LyIconModule,
    // DataTableModule,
  ],
  exports: [
    ENTRY_COMPONENTS,
    COMPONENTS,
    MODULES
  ],
  entryComponents: [
    ENTRY_COMPONENTS
  ],
  providers: [
    ActivityService,
    BaseService,
    CadConfigService,
    CustomizeService,
    FormDALService,
    MaterialDialogService,
    NavigationService,
    OcorrenciaService,
    // PdfGeneratorService,
    TarefaService,
    TasksService,
    UserService,
    CustomerConfigService,
    // CookieService,
    // ConfigStateService,
    // GlobalService,
    // LogService,
    // LoginService,
    // MsgPtBR,
    // WsTrackerService,
  ]
})
export class GeCoreModule {
  // _donePromise: Promise<void>;
  // _done: boolean;


  /* Para previnir de multiplas importações desse módulo, que deve ser singleton */
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: GeCoreModule
  ) {
    if (parentModule) {
      throw new Error('GeCoreModule is already loaded. Import it in the AppModule only');
    }
  }

  // static forRoot(token: string = null): ModuleWithProviders {
  //   // updateToken(token);
  //   return {
  //     ngModule: GeCoreModule,
  //     providers: [
  //       ConfigService,
  //       { // Para carregar as configurações do arquivo antes que as chamadas sejam realizadas
  //         provide: APP_INITIALIZER,
  //         useFactory: initConfig, // And use it here
  //         // useFactory: (appConfigService: ConfigService) => {
  //         //   return () => {
  //         //     // Make sure to return a promise!
  //         //     return appConfigService.init(token);
  //         //   };
  //         // },
  //         deps: [ConfigService],
  //         multi: true
  //       },

  //       // Services
  //       ActivityService,
  //       AsyncLocalStorageCacheService,
  //       BasePageService,
  //       BaseService,
  //       CacheService,
  //       CadConfigService,
  //       CadastroService,
  //       CustomCookieService,
  //       CustomerConfigService,
  //       CustomizeService,
  //       FormDALService,
  //       GlobalService,
  //       LogService,
  //       LoginService,
  //       MaterialDialogService,
  //       ModelComponentService,
  //       ModelService,
  //       MsgPtBR,
  //       NavigationService,
  //       ObjectDAL,
  //       OcorrenciaService,
  //       PdfGeneratorService,
  //       ProcessService,
  //       StoreProviderService,
  //       TarefaService,
  //       TasksService,
  //       TbQueueService,
  //       // TbStoreService,
  //       UserService,
  //       WebService,
  //       WsTrackerService,
  //       ModelComponent,
  //       CookieService,
  //     ]
  //   };
  // }

}
