import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnMaterialIcon, IKeyValue } from '@medlogic/shared/gecore';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { DocGeneratorService } from '../../service/doc-generator.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface DialogData {
  url: string;
  replaceVariables: IKeyValue[];
  fileName: string;
  barcodeNum: string;
  qrcodeUrl: string;
  icon?: EnMaterialIcon;
  btnOK?: string;
  isDark?: boolean;
}

/* Component that will get the template url and variables, consume the api to convert the document and return it to the user. */
@Component({
  selector: 'lib-doc-generate-dialog',
  templateUrl: './doc-generate-dialog.component.html',
  styleUrls: ['./doc-generate-dialog.component.css']
})
export class DocGenerateDialogComponent extends UnsubscribeOnDestroyAdapter {

  /* Icon do Material: https://material.io/tools/icons/?search=check&style=outline */
  icon = 'assignment';
  url: string;
  replaceVariables: IKeyValue[];
  href = '';
  fileName: string;
  isLoading = true;
  isDark = false;

  public get title(): string {
    return this.isLoading ? 'Aguarde...' : 'Baixar o documento gerado:';
  }

  constructor(
    private docGenSrv: DocGeneratorService,
    private log: LogService,
    public dialogRef: MatDialogRef<DocGenerateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super();
    try {
      this.icon = data.icon || this.icon;
      this.url = data.url;
      this.fileName = data.fileName;
      this.isDark = data.isDark;
      this.replaceVariables = data.replaceVariables;
      this.subs.sink = this.generateDoc(
        data.url,
        data.replaceVariables,
        this.fileName.toLowerCase(),
        data.barcodeNum,
        data.qrcodeUrl
      ).subscribe(s => this.isLoading = false);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  generateDoc(url: string, replaceVariables: IKeyValue[], fileName: string, barcodeNum: string, qrcodeUrl: string): Observable<any> {
    try {
      return this.docGenSrv
        .generateDoc(url, replaceVariables, barcodeNum, qrcodeUrl)
        .pipe(
          this.downloadFile('application/ms-word', fileName),
          this.error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'generateDoc', error.message);
    }
  }

  /*
  * Method is use to download file.
  * @param data - Array Buffer data
  * @param type - type of the document.
  */
  downloadFile = (type: string, fileName: string) => tap((data: any) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    this.href = url;
    this.fileName = fileName;
  })

  error = () => catchError((err, obs) => {
    console.log(err);
    return of(err);
  })

}

