import { LogService } from '@medlogic/shared/shared-interfaces';
import { slideInOut, fadeAnimation } from '@medlogic/shared/utils';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-loading-lettering',
  templateUrl: './loading-lettering.component.html',
  styleUrls: ['./loading-lettering.component.css'],
  animations: [
    slideInOut,
    fadeAnimation
  ]
})
export class LoadingLetteringComponent implements OnInit {

  @Input() lettering = [];
  @Input() delay = 2900;

  index = 0;

  constructor(
    private log: LogService
  ) { }

  ngOnInit() {
    setTimeout(() => this.repeat(this.delay), this.delay);
  }

  private repeat(delay: number = 2000): void {
    try {
      if (this.lettering?.length > 0) {
        this.lettering[this.index].state = 'out';
        this.index++;
        if (this.index < this.lettering?.length) {
          this.lettering[this.index].state = 'in';
          setTimeout(() => this.repeat(delay), delay);
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'repeat', error.message);
    }
  }


}
