import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { ILogState } from '@medlogic/shared/shared-interfaces';
import { ILog } from '@medlogic/shared/shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as LogActions from './log.actions';

export const logsFeatureKey = 'log';

const glb = new GlobalService();

export const adapterLog: EntityAdapter<ILog> = createEntityAdapter<ILog>({
  selectId: instance => instance.id,
  sortComparer: usersSortFn
});

export const initialStateLog: ILogState = adapterLog.getInitialState({
  // additional entity state properties
  selectedId: null,
  error: undefined,
});

function usersSortFn(a: ILog, b: ILog) {
  return a.date.getTime() - b.date.getTime();
}

export const reducer = createReducer(
  initialStateLog,
  on(LogActions.addLog,
    (state, action) => adapterLog.addOne(getLog(action.log), state)
  ),
  on(LogActions.setLog,
    (state, action) => ({ ...state, selectedId: action.selectedId })
  ),
  on(LogActions.upsertLog,
    (state, action) => adapterLog.upsertOne(getLog(action.log), state)
  ),
  on(LogActions.addLogs,
    (state, action) => adapterLog.addMany(getLogs(action.logs), state)
  ),
  on(LogActions.upsertLogs,
    (state, action) => adapterLog.upsertMany(getLogs(action.logs), state)
  ),
  on(LogActions.updateLog,
    (state, action) => adapterLog.updateOne(action.log, state)
  ),
  on(LogActions.updateLogs,
    (state, action) => adapterLog.updateMany(action.logs, state)
  ),
  on(LogActions.deleteLog,
    (state, action) => adapterLog.removeOne(action.id, state)
  ),
  on(LogActions.deleteLogs,
    (state, action) => adapterLog.removeMany(action.ids, state)
  ),
  on(LogActions.loadLogsSuccess,
    (state, action) => adapterLog.upsertMany(getLogs(action.logs, false), state)
  ),
  on(LogActions.logFail,
    (state, action) => ({
      ...state,
      error: action?.error
    })
  ),
  on(LogActions.clearLogs,
    state => adapterLog.removeAll(state)
  ),
  on(LogActions.clearSelectedLog,
    state => ({ ...state, selectedId: null })
  ),
  on(LogActions.loadLogs,
    state => ({ ...state })
  ),
);

const getLogs = (logs: ILog[], forceNewId: boolean = false) => {
  return logs.map(log => getLog(log, forceNewId));
}

const getLog = (log: ILog, forceNewId: boolean = false) => {
  const id = !log?.id || forceNewId ? glb.getGUID() : log.id;
  return { ...log, date: new Date(), id }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterLog.getSelectors();

export const getSelectedId = (state: ILogState) => state.selectedId;