import { StockSyncDialogComponent } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { executeQueue } from '@medlogic/shared/state-queue';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { selectQueueCount } from '@medlogic/shared/state-queue';
import { LocalLibService } from '@medlogic/shared/shared-interfaces';
import { MaterialDialogService } from '@medlogic/shared/gecore';
import { Component, OnInit } from '@angular/core';
import { ConfigPwaMedLogicService } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { NavigationPwaService, CardPatientProvider } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { DadoDALCustom } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { LocalMsgPtBR } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { LocalLoginService } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { IDataBase } from 'jsstore';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  UnsubscribeOnDestroyAdapter,
  LogService,
  GlobalService
} from '@medlogic/shared/shared-interfaces';
import {
  CadastroService
} from '@medlogic/shared/shared-data-access';
import {
  TbQueueService, TbKeyValueService, JsStoreService
} from '@medlogic/shared/shared-jsstore';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'ml-pwa-navigation-view',
  templateUrl: './pwa-navigation-view.component.html',
  styleUrls: ['./pwa-navigation-view.component.css']
})
export class PwaNavigationViewComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  dbName = 'medlogicDb';
  pendingStock$: Observable<number> = this.store.pipe(select(state => selectQueueCount(state.queue)));

  public get pending(): number {
    return this.cnf.pending;
  }

  private _hasMedication = true;
  public get hasMedication(): boolean {
    return this._hasMedication;
  }
  public set hasMedication(v: boolean) {
    this._hasMedication = v;
  }

  private _hasKitchen = true;
  public get hasKitchen(): boolean {
    return this._hasKitchen;
  }
  public set hasKitchen(v: boolean) {
    this._hasKitchen = v;
  }

  public get showMenu(): boolean {
    return this.cnf.showMenu;
  }

  public get saved(): number {
    return this.cnf.saved;
  }

  public get userName(): string {
    return this.cnf.usuarioLogadoNome;
  }

  public get isBtnSyncEnabled(): boolean {
    return this.pending > 0;
  }

  public get isMobile(): boolean {
    return this.glb.isMobile();
  }

  constructor(
    private log: LogService,
    private cadastroSrv: CadastroService,
    public cnf: ConfigPwaMedLogicService,
    private loginSrv: LocalLoginService,
    private glb: GlobalService,
    private snackBar: MatSnackBar,
    private msg: LocalMsgPtBR,
    private jsStoreSrv: JsStoreService,
    private tbQueue: TbQueueService,
    private tbKeyValue: TbKeyValueService,
    private dadoSrv: DadoDALCustom,
    private nav: NavigationPwaService,
    public pv: CardPatientProvider,
    private mat: MaterialDialogService,
    private matDialog: MatDialog,
    private lib: LocalLibService,
    private store: Store<IAppMedlogicState>,
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.cadastroSrv.updatePending();
      this.startIndexedDb(this.dbName);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Inicializa o Banco de Dados do tipo IndexedDb. */
  protected startIndexedDb(dbName: string): void {
    try {
      const dbSchema = {
        name: dbName,
        tables: [
          this.tbQueue.getTableSchema(),
          // this.tbKeyValue.getTableSchema(), Não está em uso
        ]
      } as IDataBase;
      this.jsStoreSrv.createDb(dbSchema);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'startIndexedDb', error.message);
    }
  }

  offLineClick(isOffLineMode: boolean): void {
    try {
      if (!isOffLineMode) {
        this.mat.openDialogAlert(
          'Ativação do Modo Offline',
          'Ao ativar esse módulo, todos os dados serão carregados para que fique independente do sinal com a Internet. Quer continuar?'
        ).subscribe(wasConfirmed => {
          if (wasConfirmed) {
            this.pv.isOffLineMode = !isOffLineMode;
            this.nav.gotoCardPatient();
          }
        });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'offLineClick', error.message);
    }
  }

  /* Click no botão para sincronizar os dados. */
  onSync($event: any): void {
    try {
      if (navigator.onLine) {
        this.openSnackBar(this.msg.SYNC_DATA_BEING_SAVED);
        this.subs.sink = this.cadastroSrv.executeQueue()
          .pipe(
            mergeMap(() => this.dadoSrv.executeQueue()),
            tap(() => {
              this.loginSrv.cleanAllCaches();
              this.nav.gotoLogin();
            })
          ).subscribe();
      } else {
        this.openSnackBar(this.msg.DEVICE_INTERNET_CONECTION_NOT_AVAILABLE);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onSync', error.message);
    }
  }

  /* Click no botão para sincronizar os dados de estoque. */
  onSyncStock($event: any): void {
    try {
      if (navigator.onLine) {
        this.store.dispatch(executeQueue());
        const width = '100vw';
        const height = '100vh';
        this.matDialog.open(StockSyncDialogComponent, {
          width,
          height,
          id: '',
          minWidth: width,
          maxWidth: width,
          minHeight: height,
          maxHeight: height,
          data: {}
        });
      } else {
        this.openSnackBar(this.msg.DEVICE_INTERNET_CONECTION_NOT_AVAILABLE);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onSyncStock', error.message);
    }
  }

  /* Sair. Limpará todos os caches. */
  onLogoff($event: any): void {
    try {
      this.loginSrv.logoff();
      this.nav.gotoLogin();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onLogoff', error.message);
    }
  }

  /* Ir para módulo de medicação. */
  onMedication($event: any): void {
    try {
      this.nav.gotoMedicationHome(this.cnf.tenantId);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onMedication', error.message);
    }
  }

  /* Ir para módulo de cozinha. */
  onKitchen($event: any): void {
    try {
      this.nav.gotoKitchen(this.cnf.tenantId);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onKitchen', error.message);
    }
  }

  /* Fará a configuração para forçar o comportamento de dispositivos móveis. */
  forceMobile(forceDesktop: boolean): void {
    try {
      this.cnf.isForceMobile = !forceDesktop;
      this.glb.forceMobile = !forceDesktop;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'forceMobile', error.message);
    }
  }

  /* Checa se o usuário já está logado.  */
  isLogged(): boolean {
    try {
      return !this.glb.isNullOrEmpty(this.cnf.baseUsuarioToken);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isLogged', error.message);
    }
  }

  /* Exibe a mensagem de offline. */
  protected openSnackBar(message: string): void {
    try {
      this.snackBar.open(message, null, {
        duration: 3000
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'openSnackBar', error.message);
    }
  }

  onFullScreenClick($event: any): void {
    try {
      this.lib.toogleFullscreen();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onFullScreenClick', error.message);
    }
  }


}
