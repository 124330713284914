import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { GlobalService, LocalLibService, LogService } from '@medlogic/shared/shared-interfaces';
import { BusinessUnitService } from './business-unit.service';
import { ConfigStateService } from '@medlogic/shared/state-config';

@Injectable()
export class BusinessUnitCustomService extends BusinessUnitService {

    constructor(
     cadastroSrv: CadastroService,
     glb: GlobalService,
     cnf: ConfigStateService,
     lib: LocalLibService,
     log: LogService) {
           super(cadastroSrv, glb, cnf, lib, log);
        try {
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'constructor', error.message);
        }
    }

}