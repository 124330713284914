import { GlobalService } from './global.service';
import { LogService } from './log.service';
import { EnTypedValue } from '../enum/en-typed-value.enum';
import { IListItem } from '../interface/ilist-item';
import { Injectable } from '@angular/core';
import { IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { InputMaskType } from '../interface/input-mask-type';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class LocalLibService {
  MAX_LINHA = 450;
  MAX_COLUNA = 950;
  LAST_TOP_INVISIBLE_POSITION = 10;
  DEFAULT_LEFT_INVISIBLE_POSITION = 1100;
  COMPONENT_HEIGHT = 40;

  CTRDATE = 'EDITORATIVIDADE.CTRDATE';
  CTRLABEL = 'EDITORATIVIDADE.CTRLABEL';
  CTRTEXTBOXLABELED = 'EDITORATIVIDADE.CTRTEXTBOXLABELED';
  CTRMULTILINETEXTBOXLABELED = 'EDITORATIVIDADE.CTRMULTILINETEXTBOXLABELED';
  CTRMULTILINETEXTBOXHTMLLABELED = 'EDITORATIVIDADE.CTRMULTILINETEXTBOXHTMLLABELED';
  CTRCOMBOBOX = 'EDITORATIVIDADE.CTRCOMBOBOX';
  CTRIMAGELIST = 'EDITORATIVIDADE.CTRIMAGELIST';
  CTRRADIOBUTTON = 'EDITORATIVIDADE.CTRRADIOBUTTON';
  CTRRATINGSTAR = 'EDITORATIVIDADE.CTRRATINGSTAR';
  CTRRATINGPROGRESS = 'EDITORATIVIDADE.CTRRATINGPROGRESS';
  CTRVIDEO = 'EDITORATIVIDADE.CTRVIDEO';
  CTRMAPA = 'EDITORATIVIDADE.CTRMAPA';
  CTRCHECKBOXLIST = 'EDITORATIVIDADE.CTRCHECKBOXLIST';
  CTRGRID = 'EDITORATIVIDADE.CTRGRID';

  private replace: any[] = [
    { de: 'Á', para: 'A' },
    { de: 'É', para: 'E' },
    { de: 'Í', para: 'I' },
    { de: 'Ó', para: 'O' },
    { de: 'Ú', para: 'U' },
    { de: 'À', para: 'A' },
    { de: 'È', para: 'E' },
    { de: 'Ì', para: 'I' },
    { de: 'Ò', para: 'O' },
    { de: 'Ù', para: 'U' },
    { de: 'Ä', para: 'A' },
    { de: 'Ë', para: 'E' },
    { de: 'Ï', para: 'I' },
    { de: 'Ö', para: 'O' },
    { de: 'Ü', para: 'U' },
    { de: 'Ã', para: 'A' },
    { de: 'Õ', para: 'O' },
    { de: 'Â', para: 'A' },
    { de: 'Ê', para: 'E' },
    { de: 'Î', para: 'I' },
    { de: 'Ô', para: 'O' },
    { de: 'Û', para: 'U' },
    { de: 'Ç', para: 'C' }
  ];

  // https://www.npmjs.com/package/expr-eval
  // arrayConditionSimbol: Array<IReplace> = new Array(
  //   { search: '==', replace: '==' },
  //   { search: '>', replace: '>' },
  //   { search: '<', replace: '<' },
  //   { search: '<=', replace: '<=' },
  //   { search: '>=', replace: '>=' },
  //   { search: '=', replace: '==' },
  //   { search: '&', replace: ' and ' },
  //   { search: ' OR ', replace: ' or ' },
  //   { search: ' AND ', replace: ' and ' },
  //   { search: '&&', replace: ' and ' },
  //   { search: '\\|', replace: ' or ' },
  //   { search: '\\|\\|', replace: ' or ' },
  //   { search: '\\^', replace: ' or ' },
  //   { search: 'NOT', replace: '!' },
  //   { search: '\\!', replace: '!' },
  //   { search: '<>', replace: '!=' },
  //   { search: '!=', replace: '!=' },
  //   { search: '&&&&', replace: ' and ' },
  //   { search: '&&&', replace: ' and ' },
  //   { search: '\\|\\|\\|\\|', replace: ' or ' },
  //   { search: '\\|\\|\\|', replace: ' or ' },
  //   { search: '\\!==', replace: '!=' },
  //   { search: '===', replace: '==' },
  //   { search: '>==', replace: '>=' },
  //   { search: '<==', replace: '<=' }
  // ); // Substituição de &&&& necessária pois se o usuário usar && serão substituídos por &&&& no replace de &.

  constructor(private global: GlobalService, private log: LogService) { }

  // MÉTODOS ESPECÍFICOS ******************
  /* Retornar o ValorTexto conforme o local e estrutura de armazenamento de cada componente.
   * O ValorTexto seguirá ao padrão da máscara, ou seja, se forem máscaras ptBR, manterá em ptBR.
   * Retornará o ValorData se for um CTRDATE.
   * Retornará os itens do grid empacotados num XML compatível com o ValorTexto.
   * canBeEmpty: Irá controlar se é possível que o item esteja vazio: ocorre no caso de itens de um grid serem excluídos, por exemplo.
   */
  // getValorTextoOrData(ctrl: IAtividadeComponenteDAL, isToSave: boolean = false, canBeEmpty: boolean = false): any {
  //   try {
  //     let value;
  //     switch (ctrl.Type.toUpperCase()) {
  //       case this.CTRGRID:
  //         const itemsFromCadastroAdicional = this.getGridItems(
  //           ctrl.lstCadastroAdicional,
  //           ctrl.VariavelRetornoNo,
  //           ctrl.TypeRegister
  //         );
  //         if (!itemsFromCadastroAdicional && !canBeEmpty) {
  //           return this.global.ConvertToCData(ctrl.ValorTexto);
  //         } else {
  //           return itemsFromCadastroAdicional || '<Items></Items>';
  //         }
  //       case this.CTRDATE:
  //       case 'EDITORATIVIDADE.DATETIME':
  //         return this.getValorData(ctrl, isToSave);
  //       case this.CTRTEXTBOXLABELED:
  //         if (isToSave) {
  //           value = this.global.ConvertToCData(ctrl.ValorTexto) || '';
  //         } else {
  //           value = ctrl.ValorTexto
  //             ? ctrl.ValorTexto
  //             : ctrl.ValorDefault ? (this.isFormula(ctrl.ValorDefault) ? '' : ctrl.ValorDefault) : '';
  //           if (InputMaskType.toEnum(ctrl.InputMaskType) === InputMaskType.Date) {
  //             value = this.global.DateToddMMYYYY(this.global.getTypedValue(value).value);
  //           }
  //         }
  //         return InputMaskType.cleanMaskSymbolAndConvertToPtBr(value, ctrl.InputMaskType);
  //       default:
  //         if (isToSave) {
  //           return this.global.ConvertToCData(ctrl.ValorTexto) || '';
  //         } else {
  //           value = ctrl.ValorTexto
  //             ? ctrl.ValorTexto
  //             : ctrl.ValorDefault ? (this.isFormula(ctrl.ValorDefault) ? '' : ctrl.ValorDefault) : '';
  //           if (value && value.name) {
  //             // Valor em combobox
  //             return value.name;
  //           } else {
  //             return value;
  //           }
  //         }
  //     }
  //   } catch (error) {
  //     console.log(this.constructor.name, 'getValorTextoOrData', error.message);
  //   }
  //   return '';
  // }

  /** Checa se o valor é uma fórmula matemática, ou então uma condicional */
  // isFormulaOrCondition(valor: any): boolean {
  //   try {
  //     return this.isFormula(valor) || this.isCondition(valor);
  //   } catch (error) {
  //     console.log(this.constructor.name, 'isFormulaOrCondition', error.message);
  //   }
  //   return false;
  // }

  /** Verifica se o valor corresponde a uma fórmula */
  isFormula(valor: any): boolean {
    try {
      if (this.global.IsNullOrEmpty(valor)) {
        return false;
      }
      valor = valor.toString();
      if (this.global.ChecaAspas(valor)) {
        valor = this.global.RemoverAspas(valor);
      }
      return valor.trim().substr(0, 1) === '=';
    } catch (error) {
      console.log(this.constructor.name, 'isFormula', error);
    }
    return false;
  }

  /** Verifica se o valor corresponde a uma fórmula condicional */
  // isCondition(valor: any): boolean {
  //   try {
  //     let isCondition = false;
  //     if (this.global.IsNullOrEmpty(valor)) {
  //       return false;
  //     }
  //     valor = valor.toString();
  //     if (this.global.ChecaAspas(valor)) {
  //       valor = this.global.RemoverAspas(valor);
  //     }
  //     // Utilizado some ao invés de forEach para permitir interromper o loop uma vez que a condição for encontrada
  //     this.arrayConditionSimbol.some((item, i, ar) => {
  //       if (valor.indexOf(item.search) >= 0 || valor.toUpperCase().indexOf(item.search.toUpperCase()) >= 0) {
  //         isCondition = true;
  //         return true; // Apenas para encerrar o loop some
  //       }
  //     });
  //     return isCondition;
  //   } catch (error) {
  //     console.log(this.constructor.name, 'isCondition', error);
  //   }
  //   return false;
  // }

  /* Retorna o xml de itens para compor o ValorTexto de um Grid.
   * typeRegister == 1: Id / 2: Titulo / 3: enabled
   */
  getGridItems(items: any[], variavelRetornoNo: number, typeRegister: number): string {
    try {
      if (!items || items.length <= 0) {
        return null;
      }
      let strItems = '<Items>';
      if (items && items.length > 0) {
        items.forEach((item) => {
          strItems += '<Item>';
          for (const clmName in item) {
            if (clmName !== '$') {
              const tagName = clmName === 'OcorrenciaNo' ? 'index' : clmName;
              let value;
              if (typeRegister === 1) {
                // Força a gravação do número da Ocorrência no campo de código.
                value = !item.index ? '' : item.index.name || item.index;
                strItems += `<${tagName}>${value}</${tagName}>`;
              } else {
                // Não é a propriedade de id/index
                // Porque se for combobox, o valor é um objeto
                value = !item[clmName] ? '' : item[clmName].name || item[clmName];
                const typed = this.global.getTypedValue(value);
                if (typed.type === EnTypedValue.Date) {
                  value = this.global.RetornarAllXsdDateTime(typed.value);
                }
                strItems += `<${tagName}>${value}</${tagName}>`;
              }
              if (tagName === `V_${variavelRetornoNo.toString()}` && !item.label) {
                // Acrescenta a tag label, caso não exista
                strItems += `<label>${value}</label>`;
              }
            }
          }
          strItems += '</Item>';
        });
      }
      strItems += '</Items>';
      return this.global.ConvertToCData(strItems);
    } catch (error) {
      console.log(this.constructor.name, 'getGridItems', error.message);
    }
    return '';
  }

  /* Acrescenta um elemento no array somente se ele não existir,
  * baseado na comparação do fieldNameToCompare( que deve existir tanto
  * nos elementos do array quanto no el) */
  addIfNotExist(array: any[], el: any, fieldNameToCompare: string): boolean {
    try {
      if (array.findIndex((f) => this.global.isEqual(f[fieldNameToCompare], el[fieldNameToCompare])) < 0) {
        array.push(el);
        return true;
      }
      return false;
    } catch (error) {
      console.log(this.constructor.name, 'addIfNotExist', error.message);
    }
  }

  /** retorna um id para o controle baseado na variavelNo */
  getId(id: number): string {
    try {
      if (!this.global.isNumeric(id)) {
        return '';
      }
      return `V_${id}`;
    } catch (error) {
      console.log(this.constructor.name, 'getId', error.message);
    }
  }

  /*
  * Converte uma lista de componentes num objeto do padrão do ReactiveForms.
  * forceIdAsOcorrenciaNo: Se TypeRegister == 1, o valor considerado será o número da ocorrenciaNo.
  * Desta maneira, irá forçar que todos os campos códigos tenham exatamente o valor da ocorrencia.
  */
  // mapComponentesToFormControl(
  //   componentes: Array<IAtividadeComponenteDAL>,
  //   forceIdAsOcorrenciaNo: boolean = true
  // ): any {
  //   try {
  //     const obj = {};
  //     if (componentes && componentes.length > 0) {
  //       componentes.forEach((ctrl) => {
  //         if (ctrl.TypeRegister === 1 && forceIdAsOcorrenciaNo) {
  //           obj[this.getId(ctrl.VariavelNo)] =
  //             ctrl.OcorrenciaNo > 0 ? ctrl.OcorrenciaNo : this.global.getTypedValue(ctrl.Valor).value;
  //         } else {
  //           obj[this.getId(ctrl.VariavelNo)] = this.global.getTypedValue(ctrl.Valor).value;
  //         }
  //       });
  //     }
  //     return obj;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'mapComponentesToFormControl', error.message);
  //   }
  //   return null;
  // }

  /** Faz o processo inverso de getId: a partir do id retorna a VariavelNo */
  getVariavelNoFromId(id: string): number {
    try {
      if (!id) {
        return null;
      }

      return parseInt(id.toString().replace('V_', ''), 10);
    } catch (error) {
      console.log(this.constructor.name, 'getVariavelNoFromId', error.message);
    }
    return null;
  }

  /** A lista de itens do cadastro adicional são empacotados nesse formato pelo XML. */
  getItemDoCadastro(m: any): any[] { // ICadastroListaDAL
    try {
      // m.lstCadastroAdicional.diffgram.DocumentElement.ItemDoCadastro
      if (m && m.lstCadastroAdicional !== undefined) {
        if (m.lstCadastroAdicional.diffgram !== undefined) {
          if (m.lstCadastroAdicional.diffgram.DocumentElement !== undefined) {
            if (m.lstCadastroAdicional.diffgram.DocumentElement.ItemDoCadastro !== undefined) {
              return m.lstCadastroAdicional.diffgram.DocumentElement.ItemDoCadastro;
            }
          }
        }
      }
      return null;
    } catch (error) {
      console.log(this.constructor.name, 'getItemDoCadastro', error.message);
    }
  }

  /* Se o items tiver apenas um item e for um objeto ao invés de um array, coloca o elemento num array.
   * Algumas estruturas, como interações de for, datatable, etc, no html dependem de trabalhar com arrays,
   * mas o retorno do serviço às vezes traz objetos únicos como arrays.
   * Agora, se o array for null ou só contiver elemementos null, retornará null
   */
  toArray(items: any): any[] {
    try {
      if (!items) {
        return null;
      }
      // Se o retorno tiver apenas um elemento, é necessário convertê-lo para um array
      if (items instanceof Array) {
        if (!items.find((f) => f !== null)) {
          return null;
        }
        return items;
      } else {
        return [items];
      }
    } catch (error) {
      console.log(this.constructor.name, 'toArray', error.message);
    }
    return null;
  }

  /** Verifica se o controle tem cascata ativado. */
  // isCascade(ctrl: IAtividadeComponenteDAL): boolean {
  //   try {
  //     return !this.global.IsNullOrEmpty(ctrl.VariavelComparacaoNo) && ctrl.VariavelComparacaoNo > 0;
  //   } catch (error) {
  //     console.log(this.constructor.name, 'isCascade', error.message);
  //   }
  // }

  /** Verifica se a aba está visível, conforme a visibilidade dos componentes contidos na aba. */
  // isTabVisible(componentes: IAtividadeComponenteDAL[], tab: IBasic): boolean {
  //   try {
  //     const tabComponents: IAtividadeComponenteDAL[] = new FilterComponentesByTabPipe(this.log).transform(
  //       componentes,
  //       tab
  //     );
  //     const visible = tabComponents.filter((f) => f.IsVisible);
  //     return visible && visible.length > 0;
  //   } catch (error) {
  //     console.log(this.constructor.name, 'isTabVisible', error.message);
  //   }
  //   return false;
  // }

  /* Extrai o valor data, com as devidas validações se o dado é existente. Retorna null caso não seja.
   * O retorno é no formato Xsd para ser enviada ao xml se isToSave = true.
   * Senão retorna data.
   */
  // getValorData(ctrl: IAtividadeComponenteDAL, isToSave: boolean): any {
  //   try {
  //     let valorData = ctrl.ValorData;
  //     if (!this.global.IsDate(valorData)) {
  //       if (!this.global.isNullOrEmpty(ctrl.ValorTexto)) {
  //         valorData = ctrl.ValorTexto;
  //       } else {
  //         return isToSave ? '' : new Date();
  //       }
  //     }
  //     const date = this.global.getTypedValue(valorData).value;
  //     if (isToSave) {
  //       return this.global.RetornarAllXsdDateTime(date);
  //     } else {
  //       return this.global.FormatarData(date);
  //     }
  //   } catch (error) {
  //     console.log(this.constructor.name, 'getValorData', error.message);
  //   }
  //   return null;
  // }

  /** Cria um formControl a partir dos valores do item */
  // getDefaultFormControls(selectedItem: IAtividadeComponenteDAL, formControl: any = null): any {
  //   try {
  //     formControl = formControl || [];
  //     formControl[this.getId(selectedItem.VariavelNo)] = this.getValorTextoOrData(selectedItem);
  //     return formControl;
  //   } catch (error) {
  //     console.log(this.constructor.name, 'getDefaultFormControls', error.message);
  //   }
  // }


  /* Irá extrair o valor default conforme a propriedade desejada do Cadastro Idoso Bem Cuidado. */
  getDefault(obj: { AtividadeCadastroNo: number, Rotulo: string, DefaultValue: any }[], propName: string): any {
    try {
      if (obj?.length <= 0) {
        return null;
      }
      const find = obj.find(m => this.global.isEqual(m.Rotulo, propName));
      return find?.DefaultValue || null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDefault', error.message);
    }
    return null;
  }

  /* Cria um formControl a partir dos valores do item */
  getDefaultFormControls(selectedItem: IAtividadeComponenteDAL, formControl: any = null): any {
    try {
      formControl = formControl || [];
      formControl[this.getId(selectedItem.VariavelNo)] = this.getValorTextoOrData(selectedItem);
      return formControl;
    } catch (error) {
      console.log(this.constructor.name, 'getDefaultFormControls', error.message);
    }
  }

  /* Retorna, de maneira unificada, as variaveis do grid e da atividade */
  getDefaultFromGridAndActivity(gridSelectedItem: any, formGroup: FormGroup): any {
    try {
      let formControl = {};
      // Preenche a propriedade que passará os valores preenchidos para a próxima Atividade.
      formControl = gridSelectedItem ? this.getDefaultFormControlsFromGrid(gridSelectedItem) : formControl;
      // Junta com as demais variáveis presentes na atividade
      formControl = Object.assign({}, formControl, this.getOtherActivityVariables(formGroup));
      return formControl;
    } catch (error) {
      console.log(this.constructor.name, 'getDefaultFromGridAndActivity', error.message);
    }
  }

  /*Acrescenta os valores de todas as demais variáveis da atividade, pois,
  * caso haja variáveis no novo item ou no editado, como a mesma VariavelNo,
  * tais itens deverão ser preenchidos.
  */
  getOtherActivityVariables(formGroup: FormGroup): any {
    try {
      return formGroup.getRawValue();
    } catch (error) {
      console.log(this.constructor.name, 'getOtherActivityVariables', error.message);
    }
  }


  /** Cria um formControl a partir dos valores do item */
  getDefaultFormControlsFromGrid(selectedItem: any): any {
    try {
      const formControl = {};
      for (const clm in selectedItem) {
        if (this.global.Left(clm, 2) === 'V_') {
          formControl[clm] = selectedItem[clm];
        }
      }
      return formControl;
    } catch (error) {
      console.log(this.constructor.name, 'getDefaultFormControls', error.message);
    }
  }

  /* Os controles fora da região de display serão automaticamente reposicionados para facilitar a visualização de debug.
   * Toda vez que resgatar uma posição, irá incrementar a última posição.
  */
  getNextOutsideDisplayAreaPosition(): { top: number; left: number } {
    try {
      const lastTopPos = this.LAST_TOP_INVISIBLE_POSITION;
      this.LAST_TOP_INVISIBLE_POSITION += this.COMPONENT_HEIGHT;
      return { top: lastTopPos, left: this.DEFAULT_LEFT_INVISIBLE_POSITION };
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getNextOutsideDisplayAreaPosition', error.message);
    }
  }

  /* Converte o ValorTexto numa lista.
   * TODO: Verificar o funcionamento quando houver mais de um grid na Atividade.
   * O problema é que o item que retorna da atividade nova não é reacrescentado no
   * ValorTexto de imediato. Se o item for limpo, o novo substituirá o anterior.
   * Se nao for limpo, mesmo apos uma exclusao, um novo item retornará com os excluídos.
   */
  // transformValorTextoInLstCadastroAdicional(ctrl: IAtividadeComponenteDAL): Observable<any> {
  //   try {
  //     const ValorTextoXML = ctrl.ValorTexto.replace('<![CDATA[', '').replace(']]>', '').replace(/\"/gi, '');
  //     return new Observable((observer) => {
  //       xml2js.parseString(
  //         ValorTextoXML,
  //         {
  //           explicitArray: true,
  //           tagNameProcessors: [processors.stripPrefix],
  //           valueProcessors: [
  //             processors.parseNumbers,
  //             processors.parseBooleans,
  //             (value) => this.parseDate(value)
  //           ]
  //         },
  //         (err, result) => {
  //           try {
  //             if (err) {
  //               this.log.Registrar(
  //                 this.constructor.name,
  //                 'transformValorTextoInLstCadastroAdicional.err',
  //                 err
  //               );
  //               observer.next(null);
  //               observer.complete();
  //             } else {
  //               const items = !result ? [] : result.Items.Item || [];
  //               // TODO: (CORRIGIR NO SERVIÇO) O serviço está trazendo a tag label duplicada,
  //               // sendo um elemento vazio, que acaba se tornando um array com dois elementos.
  //               if (items) {
  //                 items.forEach((f) => {
  //                   try {
  //                     // f.label = [f.label[1]];
  //                     f.label = [this.extractLabel(ctrl, f)];
  //                     for (const clm in f) {
  //                       if (clm) {
  //                         const value = f[clm];
  //                         const typed = this.global.getTypedValue(value);
  //                         // TODO: modificar para o resgate do valor traz impacto para as máscaras e apresentação dos campos?
  //                         f[clm] = typed.value;
  //                       }
  //                     }
  //                   } catch (error) {
  //                     this.log.Registrar(
  //                       this.constructor.name,
  //                       'transformValorTextoInLstCadastroAdicional.forEach',
  //                       error.message
  //                     );
  //                   }
  //                 });
  //               }
  //               observer.next(items);
  //               observer.complete();
  //             }
  //           } catch (error) {
  //             this.log.Registrar(
  //               this.constructor.name,
  //               'transformValorTextoInLstCadastroAdicional.observable',
  //               error.message
  //             );
  //             observer.next(null);
  //             observer.complete();
  //           }
  //         }
  //       );
  //     });
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'transformValorTextoInLstCadastroAdicional', error.message);
  //   }
  //   return of(null);
  // }

  /** extrai o valor da label em várias situações. */
  // extractLabel(ctrl: IAtividadeComponenteDAL, formControl: any): string {
  //   try {
  //     let label;
  //     if (formControl.hasOwnProperty('label')) {
  //       label = Array.isArray(formControl.label) ? formControl.label[0] : formControl.label;
  //     } else {
  //       const lblVarNo = this.getId(ctrl.LstCamposAdicionais.CamposAdicionais[0].VariavelNo);
  //       label = this.global.getTypedValue(formControl[lblVarNo]).string;
  //     }
  //     return label;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'extractLabel', error.message);
  //   }
  // }

  /** Se for uma data, transforma a string no tipo data  */
  parseDate(value: any): any {
    try {
      return this.global.getTypedValue(value).string;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'parseDate', error.message);
    }
  }

  /** Retorna um valor booleano a partir da comparação de um valor do tipo 'SIM'. */
  getBoolean(value: string, yesValue: string = 'SIM'): boolean {
    try {
      return this.global.getBoolean(value, yesValue);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getBoolean', error.message);
    }
  }

  protected primeiraMaiuscula(name: string): string {
    try {
      name = this.global.removeConjuncao(name);
      return `${name.split(' ').map(m => m.substr(0, 1).toUpperCase()).join('.')}.`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getInitials', error.message);
    }
    return name;
  }

  getCustomerIdVnoFromModules(): string {
    try {
      const vno = this.getCustomerIdsFromModules().id;
      return `V_${vno}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCustomerIdFromModules', error.message);
    }
    return null;
  }

  getCustomerIdentification1VnoFromModules(): string {
    try {
      const vno = this.getCustomerIdsFromModules().identification1;
      return `V_${vno}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCustomerIdentification1VnoFromModules', error.message);
    }
    return null;
  }

  getCustomerIdentification2VnoFromModules(): string {
    try {
      const vno = this.getCustomerIdsFromModules().identification2;
      return `V_${vno}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCustomerIdentification2VnoFromModules', error.message);
    }
    return null;
  }

  getCustomerIdsFromModules(): IListItem<any> {
    try {
      // return this.cnf.modules.find(f => f.isCustomerModule).identification;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCustomerIdsFromModules', error.message);
    }
    return null;
  }

  /* Ativa/desativa fullscreen se estiver disponível.
  * Por motivo de segurança do navegador, deve ser chamado a partir de um evento do usuário.
  */
  toogleFullscreen(forceEnable: boolean = true): void {
    try {
      // const sf = screenfull as Screenfull;
      // if (sf.isEnabled && ((forceEnable && !sf.isFullscreen) || !forceEnable)) {
      //   sf.toggle();
      // }
      const doc: any = window?.document;
      const docEl: any = doc?.documentElement;

      const requestFullScreen = docEl?.requestFullscreen || docEl?.mozRequestFullScreen || docEl?.webkitRequestFullScreen || docEl?.msRequestFullscreen;
      const cancelFullScreen = doc?.exitFullscreen || doc?.mozCancelFullScreen || doc?.webkitExitFullscreen || doc?.msExitFullscreen;

      if (!doc?.fullscreenElement && !doc?.mozFullScreenElement && !doc?.webkitFullscreenElement && !doc?.msFullscreenElement) {
        requestFullScreen.call(docEl);
      }
      else {
        cancelFullScreen.call(doc);
      }

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toogleFullscreen', error.message);
    }
  }

  getValorTextoOrData(ctrl: IAtividadeComponenteDAL, isToSave: boolean = false, canBeEmpty: boolean = false): any {
    try {
      let value: any;
      switch (ctrl.Type.toUpperCase()) {
        case this.CTRGRID:
          const itemsFromCadastroAdicional = this.getGridItems(
            ctrl.lstCadastroAdicional,
            ctrl.VariavelRetornoNo,
            ctrl.TypeRegister
          );
          if (!itemsFromCadastroAdicional && !canBeEmpty) {
            return this.global.ConvertToCData(ctrl.ValorTexto);
          } else {
            return itemsFromCadastroAdicional || '<Items></Items>';
          }
        case this.CTRDATE:
        case 'EDITORATIVIDADE.DATETIME':
          return this.getValorData(ctrl.ValorData, isToSave);
        case this.CTRTEXTBOXLABELED:
          if (isToSave) {
            value = this.global.ConvertToCData(ctrl.ValorTexto) || '';
          } else {
            value = ctrl.ValorTexto
              ? ctrl.ValorTexto
              : ctrl.ValorDefault ? (this.isFormula(ctrl.ValorDefault) ? '' : ctrl.ValorDefault) : '';
          }
          return InputMaskType.cleanMaskSymbolAndConvertToPtBr(value, ctrl.InputMaskType);
        default:
          if (isToSave) {
            return this.global.ConvertToCData(ctrl.ValorTexto) || '';
          } else {
            value = ctrl.ValorTexto
              ? ctrl.ValorTexto
              : ctrl.ValorDefault ? (this.isFormula(ctrl.ValorDefault) ? '' : ctrl.ValorDefault) : '';
            if (value && value.name) {
              // Valor em combobox
              return value.name;
            } else {
              return value;
            }
          }
      }
    } catch (error) {
      console.log(this.constructor.name, 'getValorTextoOrData', error.message);
    }
    return '';
  }


  /*Extrai o valor data, com as devidas validações se o dado é existente. Retorna null caso não seja.
  * O retorno é no formato Xsd para ser enviada ao xml se isToSave = true.
  * Senão retorna data.
  */
  getValorData(valorData: any, isToSave: boolean): any {
    try {
      if (!this.global.IsDate(valorData)) {
        return isToSave ? '' : new Date();
      }
      const date = this.global.getTypedValue(valorData).value;
      if (isToSave) {
        return this.global.RetornarAllXsdDateTime(date);
      } else {
        return this.global.FormatarData(date);
      }
    } catch (error) {
      console.log(this.constructor.name, 'getValorData', error.message);
    }
    return null;
  }

  /*Compara se duas datas são iguais, ignorando data e hora.
     * Se uma das datas for inválida, retornará false.
     */
  isSameDate(date1: Date, date2: Date): boolean {
    try {
      if (!this.global.isValidDate(date1) || !this.global.isValidDate(date2)) {
        return false;
      }

      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isSameDate', error.message);
    }
  }

  /*Checa se é uma data válida
    // deve ser um campo no formato data
    // ou uma string do tipo dd/mm/yyyy */
  IsDate(_supostaData: any): boolean {
    try {
      if (this.isNullOrEmpty(_supostaData)) {
        return false;
      }
      if (typeof _supostaData.getMonth === 'function') {
        return true;
      } else {
        const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
        const dt = new Date(_supostaData.replace(pattern, '$3-$2-$1'));
        return this.FormatarData(dt) === _supostaData;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'IsDate', error.message);
    }
    return false;
  }

  /* Além de formatar no padrão dd/mm/yyyy corrige o mês que, no js é baseado em zero. */
  FormatarData(_data: Date): string {
    try {
      // const _strMes: string = parseInt((_data.getMonth() + 1).toString(), 10).toString();
      // const _zeroMes: string = _strMes.length === 1 ? '0' : '';
      // const _strDia: string = _data.getDate().toString();
      // const _zeroDia: string = _strDia.length === 1 ? '0' : '';
      // return _zeroDia + _strDia + '/' + _zeroMes + _strMes + '/' + _data.getFullYear();
      const typed = this.global.getTypedValue(_data);
      return typed.string;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'FormatarData', error.message);
    }
  }

  /*Converte número em português para padrão computacional
    //Se não for possível converter, retorna default. */
  convertNumber(str: any, defaultValue: number = 0): number {
    let num = defaultValue;
    try {
      if (!this.global.isNullOrEmpty(str)) {
        const typed = this.global.getTypedValue(str);
        num = typed.value;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convertNumber', error.message);
    }
    return isNaN(num) ? defaultValue : num;
  }

  // Verifica se o valor é SIM, true, 1, string, ou booleano
  convertBoolean(str: any): boolean {
    try {
      if (typeof str === 'boolean') {
        return str;
      }
      if (typeof str === 'number') {
        return str <= 0 ? false : true;
      }
      if (typeof str === 'string') {
        if (str.toUpperCase() === 'SIM' || str.toUpperCase() === 'TRUE' || str.toUpperCase() === 'VERDADEIRO') {
          return true;
        }
      }
      return false;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convertBoolean', error.message);
    }
  }

  isEqual(str1: string, str2: string): boolean {
    try {
      return this.tratar(str1) === this.tratar(str2);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isEqual', error.message);
    }
  }

  contem(str1: string, str2: string): boolean {
    try {
      return this.tratar(str1).indexOf(this.tratar(str2)) >= 0;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'contem', error.message);
    }
  }

  /* Utilizar esse método dos dois lados da expressão de comparação para ficar caseinsensitive e desconsiderar acentos */
  tratar(str: string): string {
    try {
      if (!str) {
        return str;
      }
      str = str.toLocaleUpperCase();
      for (const obj of this.replace) {
        str = str.replace(obj.de, obj.para);
      }
      return str;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'tratar', error.message);
    }
    return '';
  }

  isNullOrEmpty(str: any): boolean {
    try {
      if (str === undefined) {
        return true;
      }
      if (str === null) {
        return true;
      }
      if (str.toString() === '') {
        return true;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isNullOrEmpty', error.message);
    }
    return false;
  }

  addDays(date: Date, days: number): Date | null {
    try {
      if (!date) {
        return null;
      }
      if (!this.global.IsDate(date)) {
        return null;
      }
      const returnDate = new Date(date.valueOf());
      returnDate.setDate(returnDate.getDate() + +days);
      return returnDate;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addDays', error.message);
    }
    return null;
  }

  isBetween(dt: any, dtStart: any, dtEnd: any): boolean {
    try {
      return new Date(dt) >= new Date(dtStart) && new Date(dt) <= new Date(dtEnd);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isBetween', error.message);
    }
  }

  getMonthName(date: Date, locale: string = 'pt-BR'): string {
    try {
      const month = date.toLocaleString(locale, { month: 'short' });
      return month;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMonthName', error.message);
    }
    return null;
  }

  getMonthYear(dt: Date, locale: string = 'pt-BR'): string {
    try {
      if (!dt) {
        return null;
      }
      if (!this.global.IsDate(dt)) {
        return null;
      }
      const date: Date = new Date(dt.valueOf());
      return this.getMonthName(date, locale) + '/' + date.getFullYear();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMonthYear', error.message);
    }
    return null;
  }

  getDayMonth(dt: any, locale: string = 'pt-BR'): string {
    try {
      const date: Date = new Date(dt);
      const day: string = '0' + date.getDate();
      return this.getRight(day, 2) + '/' + this.getMonthName(date, locale);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDayMonth', error.message);
    }
  }

  getRight(str: string, num: number): string {
    try {
      return str.slice(-num);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getRight', error.message);
    }
  }

  getLeft(str: string, num: number): string {
    try {
      return str.slice(0, num);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getLeft', error.message);
    }
  }

  getGUID(): string {
    try {
      const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      };
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getGUID', error.message);
    }
  }


}
