// Class: Paciente


export interface IOption {
    id: number; // prontuario: c.V_28051
    name: string; // nomeResidente: c.V_387
    enabled: boolean; // situacaoHospede: this.lib.getBoolean(c.V_28065)
    photo: string; // c.V_3304
    link: string; //
}
