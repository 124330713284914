import { InputMaskType } from '@medlogic/shared/shared-interfaces';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { LibService } from '../service/lib.service';
import { IBubble } from '../interface/ibubble';
import { CalculadoraConditionService } from '../service/calculadora-condition.service';
import { FormGroup, AbstractControl } from '@angular/forms';
import { IVariable } from '../interface/ivariable';
import { IControl } from '../interface/icontrol';
import { CalculadoraService } from '../service/calculadora.service';
import { Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnumCtrType } from '../enum/enum-ctr-type.enum';
import { Observable } from 'rxjs';
import { LogService, IAtividadeComponenteDAL, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { WindowDialogComponent } from '@medlogic/shared/gecore';
import { EnMaterialIcon } from '@medlogic/shared/gecore';
import { EnTheme } from '@medlogic/shared/shared-interfaces';

@Injectable()
export abstract class Control extends UnsubscribeOnDestroyAdapter implements OnInit, OnChanges, IControl {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup = new FormGroup({});
  @Input() isLoading = true;
  @Input() enTheme = EnTheme.default;
  @Input() isMobile: boolean;

  // tslint:disable: no-output-on-prefix
  @Output() onChangeNotify = new EventEmitter<IVariable>();
  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble = new EventEmitter<IBubble>();

  variavelNo: number;

  ALTURACONTROLE: number = this.cnf.alturaPadrao;

  /* Define se é um dispositivo móvel. */
  // isMobile(): boolean {
  //   try {
  //     return this.global.isMobile();
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'isMobile', error.message);
  //   }
  // }

  constructor(
    protected log: LogService,
    protected global: GlobalService,
    protected lib: LibService,
    protected cnf: ConfigStateService,
    protected cnfJson: ConfigJsonService,
    protected calc: CalculadoraService,
    protected calcCond: CalculadoraConditionService,
    public dialog: MatDialog,
    protected msg: MsgPtBR
  ) {
    super();
  }

  ngOnInit(): void {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.variavelNo = this.ctrl?.VariavelNo;
  }

  /* Evitar o uso desse método, tentando usar a diretiva disabled diretamente no html.
  * Ao invés disso, como se trata de reactive forms, desativar o controle formControl.disable()
  */
  isReadOnly(ctrl: IAtividadeComponenteDAL): boolean {
    try {
      return (this.calcCond.isReadOnly(ctrl) || this.cnf.isReadOnly) && !this.cnfJson.forceAllControlsEditable;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isReadOnly', error.message);
    }
    return false;
  }
 
  /*verifica se o controle do form está com status de válido
  * Toda a validação ocorre através dos Validators acrescentados na
  * construção do form FormBuild, em AtividadeView.
  */
  isValid(formGroup: FormGroup, ctrl: IAtividadeComponenteDAL): boolean {
    try {
      const id: string = this.getId(ctrl);
      const control = formGroup?.controls[id];
      // A propriedade valid considera também se o componentes está inativo (somente leitura), que não é o objetivo dessa validação,
      // pois, pode haver componentes somente leitura, mas que estão preenchidos e válidos.
      return !ctrl.IsVisible || !control?.invalid;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isValid', error.message);
    }
  }

  /* Retorna um id para o controle baseado na variavelNo */
  getId(ctrl: IAtividadeComponenteDAL): string {
    try {
      return this.lib.getId(ctrl?.VariavelNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getId', error.message);
    }
  }

  /* Resgata o valor do formControl */
  protected getValueFrom(variavelNo: number): any {
    try {
      return this.formGroup?.controls[this.lib.getId(variavelNo)]?.value;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getValueFrom', error.message);
    }
  }

  /* Redefine o valor do controle, conforme o respectivo tipo. Também atualiza o formGroup.
  * Atenção: se for um campo de data, o value deve ser do tipo Date.
  */
  protected setValue(value: any, emitEvent: boolean = true): void {
    try {
      const enType = this.ctrl?.EnCtrType;
      const c = this.formGroup?.get(this.getId(this.ctrl));
      if ((enType === EnumCtrType.CtrDate) ||
        (InputMaskType.toEnum(this.ctrl?.InputMaskType) === InputMaskType.Date)) {
        this.ctrl.ValorData = value;
        this.ctrl.ValorDataMMddyyyy = this.global.DateToMMddYYYY(value);
        this.ctrl.Valor = this.ctrl?.ValorDataMMddyyyy;
      } else {
        this.ctrl.ValorTexto = value;
        this.ctrl.Valor = value;
      }
      const typed = this.global.getTypedValue(this.ctrl?.ValorTexto);
      c.setValue(typed.string, { onlySelf: true, emitEvent });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setValue', error.message);
    }
  }

  /* Resgata o formControl */
  getFormControlFrom(ctrl: IAtividadeComponenteDAL): AbstractControl {
    try {
      return this.formGroup?.get(this.getId(ctrl));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getValueFrom', error.message);
    }
  }

  /* Calcula a largura do controle
  * args recebe uma lista de controles html que disputem a mesma largura.
  * args também pode conter um número que representa uma largura a ser descontada.
  * exemplo getWidth(ctrl, btnAction, 32)
  */
  getWidth(ctrl: IAtividadeComponenteDAL, ...args): string {
    try {
      let width = ctrl?.Largura;
      if (this.isMobile) {
        return '100%';
      } else if (args) {
        width -= args
          .map((m) => {
            return parseFloat(m ? m.clientWidth || m : 0);
          })
          .reduce((a, b) => a + b, 0);
      }
      return `${width}px`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWidth', error.message);
    }
  }

  /* Padrões de alturas diferente para desktop e dispositivos móveis. */
  getStandardHeight(): string {
    try {
      if (this.isMobile) {
        return '30px';
      } else {
        return '21px';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getHeight', error.message);
    }
    return '';
  }

  getHeight(ctrl: IAtividadeComponenteDAL, ...args): string {
    try {
      let height = ctrl?.Altura;
      if (args) {
        height -= args.map((m) => parseFloat(m.clientHeight || m)).reduce((a, b) => a + b, 0);
      }
      return `${height}px`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getHeight', error.message);
    }
  }

  getTabIndex(ctrl: IAtividadeComponenteDAL | any): number {
    try {
      if (this.isMobile) {
        return ctrl?.TabIndex * 200 + ctrl?.TabOrder;
      } else {
        return ctrl?.TabOrder;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getTabIndex', error.message);
    }
    return -1;
  }

  onChange(): void { }

  /* Abre uma janela de diálogo com uma mensagem.
  * Retorna um Observable de fechamento.
  */
  openDialog(
    title: string,
    messageHtml: string,
    btnOK: string = 'OK',
    width: string = '450px',
    icon: EnMaterialIcon = EnMaterialIcon.warning
  ): Observable<any> {
    try {
      const dialogRef = this.dialog
        .open(WindowDialogComponent, {
          width: `${width}`,
          data: { title, messageHtml, btnOk: btnOK, icon }
        });
      return dialogRef.afterClosed();
      // .subscribe(result => {
      //     console.log('The dialog was closed');
      //     this.animal = result;
      // });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'openDialog', error.message);
    }
  }

  /* Checa se o tema black deve ser aplicado. */
  isBlack(): boolean {
    try {
      return this.enTheme === EnTheme.black;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isBlack', error.message);
    }
    return false;
  }


}
