import { IPrescription, IPrescriptionState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { createSelector } from '@ngrx/store';


export const selectAllPrescriptions = (state: IPrescriptionState) => state.allPrescriptions;
export const selectedPrescription = (state: IPrescriptionState) => state.selectedPrescription;

export const selectPrescriptionById = (id: string) => createSelector(
  selectAllPrescriptions,
  allPrescriptions => {
    if (allPrescriptions && id) {
      return allPrescriptions.find(f => +f.codigo32935 === +id);
    }
    return null;
  });

export const selectDefaultFormControl = createSelector(
  selectedPrescription,
  selected => Object
    .keys(selected.codigo32935)
    .reduce((obj, key) => {
      obj[getId(key)] = selected.codigo32935[key];
      return obj;
    }, {}));

function getId(vno: any): string {
  return isNaN(vno) ? null : `V_${vno}`;
}

export const selectAllPrescriptionsAsListItems = createSelector(
  selectAllPrescriptions,
  (allPrescriptions: []) => {
    if (allPrescriptions) {
      return allPrescriptions;
      // .map(m => (
      //   {
      //     id: m.codigo,
      //     topLeft: m.nomeEmpresa,
      //     bottomLeft: m.conta,
      //     topRight: m.bENEFICIARIO,
      //     bottomRight: m.dataAberturaConta,
      //     color: EnColor.green
      //   } as IListItem
      // ));
    }
    return [];
  });

export const selectAllPrescriptionsAsListItemsByKeyword = createSelector(
  selectAllPrescriptionsAsListItems,
  (allItems: IPrescription[], props: { keyword: string }) => {
    if (allItems) {
      return allItems.filter(f =>
        (props.keyword || '') === '' ||
        f.medicamentoPrescrito.includes(props.keyword)
      );
    } else {
      return [];
    }
  });
