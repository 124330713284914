
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';

import { LocalLibService, LogService, GlobalService } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { PrescriptionMedicationService } from './prescription-medication.service';

@Injectable()
export class PrescriptionMedicationCustomService extends PrescriptionMedicationService {

    constructor(
        cadastroSrv: CadastroService,
        glb: GlobalService,
        cnf: ConfigStateService,
        lib: LocalLibService,
        log: LogService) {
           super(cadastroSrv, glb, cnf, lib, log);
        try {
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'constructor', error.message);
        }
    }

}
