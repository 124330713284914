import { IPrescriptionMedication } from '@medlogic/shared/shared-interfaces';
import { IPrescriptionState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { createReducer, on, Action } from '@ngrx/store';
import { savePrescription, saveSuccessPrescription, saveFailPrescription, updatePrescription, setPrescription, clearPrescription } from './prescription.actions';
import {
  fetchSuccessPrescription,
  getPrescriptionsByDate,
  loadPrescription,
} from './prescription.actions';
import { update } from '@medlogic/shared/utils';

// TODO: Remember to import the Effects and Reducer at app.module
// EffectsModule.forRoot([ContaEffects]),
// StoreModule.forRoot({  app: appReducer, conta: contaReducer, conta: contaReducer }),
export const initialState: IPrescriptionState = {
  selectedPrescription: null,
  allPrescriptions: [],
  Id: null,
  prescriptionId: null,
  identification: null,
  showMessage: false
};

const prescriptionReducerObj = createReducer(initialState,
  on(loadPrescription, (state, payload) => {
    return ({
      ...state,
      bannerTitle: 'PRESCRIÇÕES',
      allPrescriptions: null,
      dtStart: payload.dtStart,
      numOfDays: payload.numOfDays
    });
  }),
  on(savePrescription, (state, payload) => ({
    ...state, // Aproximação otimista, ou seja, altera o estado mesmo antes do retorno do serviço.
    error: null
  })),
  on(updatePrescription, (state, payload) => ({
    ...state, // Aproximação otimista, ou seja, altera o estado mesmo antes do retorno do serviço.
    error: null
  })),
  on(setPrescription, (state, payload) => ({
    ...state,
    selectedPrescription: payload.prescription,
    prescriptionId: payload.prescription?.ocorrenciaNo,
    error: null
  })),
  on(saveSuccessPrescription, (state, payload) => ({
    ...state, // Modifica o estado após o retorno do serviço.
    // Não atualizar novamente, uma vez que já foi acrescentado de modo otimista
    // Prescriptions: update<IPrescription>(state?.Prescriptions, payload?.Prescription),
    allPrescriptions: update<IPrescriptionMedication>(state?.allPrescriptions, payload?.prescription),
    selectedPrescription: payload?.prescription,
    prescriptionId: payload?.prescription?.ocorrenciaNo,
    showMessage: payload.showMessage,
    error: null
  })),
  on(saveFailPrescription, (state, payload) => ({
    ...state,
    allPrescriptions: update<IPrescriptionMedication>(state?.allPrescriptions, payload?.prescription),
    selectedPrescription: payload?.prescription,
    prescriptionId: null,
    error: 'fail-on-save-prescription'
  })),
  on(getPrescriptionsByDate, (state: IPrescriptionState, payload) => {
    return ({
      ...state,
      bannerTitle: 'PRESCRIÇÕES',
      allPrescriptions: null,
      dtStart: payload.dtStart,
      numOfDays: payload.numOfDays
    });
  }),
  on(fetchSuccessPrescription, (state, payload) => {
    return ({
      ...state,
      allPrescriptions: payload.allPrescriptions && payload.allPrescriptions[0].ocorrenciaNo ? payload.allPrescriptions : null
    });
  }),
  on(fetchSuccessPrescription, (state: IPrescriptionState, payload) => ({
    ...state,
    allPrescriptions: payload.allPrescriptions,
  })),
  on(clearPrescription, (state, payload) => ({
    ...initialState,
  })),
);

export function prescriptionReducer(state: IPrescriptionState | undefined, action: Action) {
  return prescriptionReducerObj(state, action);
}
