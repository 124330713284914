import { AppMedlogicPwaCuidadoState } from './../../../../../../libs/medlogic-pwa/medlogic-pwa-cuidado-full/src/lib/module/core/ngrx/states/app-state';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { map, withLatestFrom } from 'rxjs/operators';
import { Injectable, NgModule } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
// import { AppState } from './ngrx/states/app-state';
// import { Store } from '@ngrx/store';

@Injectable()
export class MedlogicPwaCuidadoHttpRequestInterceptor implements HttpInterceptor {

    private readonly TOKEN_STR = '###TOKEN###';

    constructor(
        private cnf: ConfigStateService,
        private store: Store<AppMedlogicPwaCuidadoState>,
        private log: LogService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        try {
            return next.handle(req)
                .pipe(
                    withLatestFrom(this.store),
                    map(([event, state]) => {
                        if (event instanceof HttpResponse) {
                            // const token = this.cnf.baseUsuarioToken;
                            const token = state?.tenant?.token;
                            if (token) {
                                const dupReq = req.clone({
                                    headers: req.headers.set('Authorization', `Bearer ${token}`),
                                });
                                // if (!req.params.keys().includes('token')) {
                                //     req.params.append('token', token);
                                // }
                                event = event.clone({
                                    body: this.modifyBody(event?.body, token),
                                    headers: dupReq.headers
                                });
                            }
                        }
                        return event;
                    }),
                );
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'intercept', error.message);
        }
        return next.handle(req);
    }

    private modifyBody(body: any, token: string): any {
        try {
            // return body.replace(this.TOKEN_STR, token);
            return body;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'modifyBody', error.message);
        }
    }


}


// tslint:disable-next-line: max-classes-per-file
@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MedlogicPwaCuidadoHttpRequestInterceptor,
            multi: true,
        },
    ],
})

export class MedlogicPwaCuidadoInterceptor { }
