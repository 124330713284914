import { UiGenericListModule } from '@medlogic/shared/ui/ui-generic-list';
import { NgModule } from '@angular/core';
import { AppProvider } from './service/app.provider';
import { LocalLibService } from './service/local-lib.service';
import { LocalMsgPtBR } from './service/local-msg-ptBR.service';
import { LocalNavigationService } from './service/local-navigation.service';
import { ProcessListViewComponent } from './view/process-list-view/process-list-view.component';
import { GenericListViewComponent } from './view/generic-list-view/generic-list-view.component';
import { LocalAtividadeCadastroViewComponent } from './view/local-atividade-cadastro-view/local-atividade-cadastro-view.component';
import { NgRxNavigationService } from './service/ngrx-navigation.service';
import { GeCoreModule } from '@medlogic/shared/gecore';
import { FormsModule } from '@angular/forms';
import { GeFormModule } from '@medlogic/shared/geform';
import { GenericListComponent } from './components/generic-list/generic-list.component';
import { ItemSearchPipe } from './pipe/item-search.pipe';
import { ListItemComponent } from './components/list-item/list-item.component';
import { CommonModule } from '@angular/common';
import { LocalLoginViewComponent } from './view/local-login-view/local-login-view.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AccountEffects } from './ngrx/effects/account.effects';
import { ProcessEffects } from './ngrx/effects/process.effects';
import { GenericEffects } from './ngrx/effects/generic.effects';
import { LoginEffects } from './ngrx/effects/login.effects';
import { processReducer } from './ngrx/reducers/process.reducer';
import { accountReducer } from './ngrx/reducers/account.reducer';
import { customerReducer } from './ngrx/reducers/customer.reducer';
import { genericReducer } from './ngrx/reducers/generic.reducer';
import { appReducer } from './ngrx/reducers/app.reducer';
import { loginReducer } from './ngrx/reducers/login.reducer';
import { AccountCustomService } from './service/account.custom.service';
import { GenericCustomService } from './service/generic.custom.service';
import { LocalStorageService } from './service/local-storage.service';
import { metaReducers } from './ngrx/reducers/storage.metareducer';
import { NgLetModule } from '@ngrx-utils/store';

const COMPONENTS = [
  LocalLoginViewComponent,
  GenericListViewComponent,
  LocalAtividadeCadastroViewComponent,
  ProcessListViewComponent,
  GenericListComponent,
  ListItemComponent,
  ItemSearchPipe
];

@NgModule({
  declarations: [
    COMPONENTS
  ],
  imports: [
    CommonModule,
    FormsModule,
    GeCoreModule,
    GeFormModule,
    NgLetModule,
    UiGenericListModule,
    EffectsModule.forRoot([AccountEffects, ProcessEffects, GenericEffects, LoginEffects]),
    StoreModule.forRoot({
      process: processReducer,
      account: accountReducer,
      customer: customerReducer,
      generic: genericReducer,
      app: appReducer,
      loginPWA: loginReducer // Necessário, pois, em MedlogicState também há login // FIXME: o ideal seria um único login. Analisar regras de negócios individuais.
    }, { metaReducers }),
  ],
  exports: [
    COMPONENTS,
    GeCoreModule,
    GeFormModule,
    CommonModule,
  ],
  providers: [
    AppProvider,
    LocalLibService,
    LocalMsgPtBR,
    LocalNavigationService,
    NgRxNavigationService,
    AccountCustomService,
    GenericCustomService,
    LocalStorageService
  ],
})
export class LocalCoreModule { }
