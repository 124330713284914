import { ConfigStateService } from '@medlogic/shared/state-config';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { BasePageService, WebService } from '@medlogic/shared/shared-data-access';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LogService, ConfigJsonService, IKeyValue } from '@medlogic/shared/shared-interfaces';

@Injectable()
export class DocGeneratorService {

  urlGenerateDoc = `${this.cnfJson.baseUrlDocGen}HttpTrigger`; // 'http://localhost:7071/api/HttpTrigger';
  // securityKey = '?code=RhOYDR9ZryHLIYG3FGObH2YsaFafMsS0zS6WAWB5h5XagJExbElOBQ=='; // https://ge-file-upload.azurewebsites.net
  // securityKey = '?code=84ZkoQxIv/8WQ0YQKnnlK4mah9cilaUfVEDYLExREXXOWv6yrKuNsg=='; // https://brasilbrazil-doc-generator.azurewebsites.net
  securityKey = '?code=2Fm2/3uQwJoQ7l4sfo0FyCZnfIaTs4ngqwpWUCHgF6UM3DRM7fIHaw=='; // https://doc-email-file-api.azurewebsites.net

  get apiUrl(): string {
    return `${this.urlGenerateDoc}${this.securityKey}`;
  }

  constructor(
    private http: HttpClient,
    private basepage: BasePageService,
    private cfg: ConfigStateService,
    private cnfJson: ConfigJsonService,
    private log: LogService,
    private ws: WebService) { }

  /* Generates a docx from template. It replace variables and add QRCODE and BARCODE.
  {
    "file_name": "template.docx",
    "fields": {
          "#101573#":"Gold",
          "#101305#":"Springfield",
          "#101582#":"2020-01-01"
      },
      "table": [
        {
          "#101573#":"Gold",
          "#101305#":"Springfield",
          "#101582#":"2020-01-01"
      },
      {
          "#101573#":"Gold2",
          "#101305#":"Springfield2",
          "#101582#":"2020-01-01"
      },
      {
          "#101573#":"Gold3",
          "#101305#":"Springfield3",
          "#101582#":"2020-01-01"
      }
      ]
  }
  */
  generateDoc(templateUrl: string, params: IKeyValue[], barcodeNum: string, qrcodeUrl: string): Observable<any> {
    try {
      const data = this.generateData(params, templateUrl, barcodeNum, qrcodeUrl);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Data-Type': 'json'
      });
      return this.http.post(this.apiUrl, JSON.stringify(data), { responseType: 'arraybuffer', headers });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDadosUsuario', error.message);
    }
    return of(null);
  }

  /* Generates the data param required by the DocGen API. */
  private generateData(params: IKeyValue[], templateUrl: string, barcodeNum: string, qrcodeUrl: string): any {
    try {
      const dic = params
        .map(m => ({ ...m, key: this.getKey(m.key) } as IKeyValue))
        .reduce((dict, el, index) => (dict[el.key] = el.value, dict), {});
      const data = {
        file_url: templateUrl,
        fields: dic,
        code_num: barcodeNum || '000000000000',
        qrcode_url: qrcodeUrl || this.cnfJson.baseUrl,
        table: null
      };
      return data;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'generateData', error.message);
    }
    return null;
  }

  private getKey(key: any): string {
    try {
      return key.toString().indexOf('#') >= 0 ? key.toString() : `#${key}#`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getKey', error.message);
    }
    return '';
  }


}
