export * from './lib/medlogic-shared-interfaces.module';

// interfaces
export * from './lib/interface/imedical-test';
export * from './lib/interface/itest-result';
export * from './lib/interface/iexame';
export * from './lib/interface/iexame-resultado';
export * from './lib/interface/iperson';
export * from './lib/interface/icell-row';
export * from './lib/interface/ifrailty';
export * from './lib/interface/imedication-period';
export * from './lib/interface/iprescription';
export * from './lib/interface/istock-item';
// states
export * from './lib/interface/iexame-state';
export * from './lib/interface/iexame-resultado-state';
export * from './lib/interface/iapp-medlogic-state';
export * from './lib/interface/iperson-state';
export * from './lib/interface/ifrailty-state';
export * from './lib/interface/ipatient-state';
export * from './lib/interface/ievolution-state';
export * from './lib/interface/imedication-state';
export * from './lib/interface/istock-state';
export * from './lib/interface/ivital-sign-state';
export * from './lib/interface/iivcf-state';
export * from './lib/interface/iprescription-state';
export * from './lib/interface/ihorarios-state';
export * from './lib/interface/itutorial-state';


// enum
export * from './lib/enum/en-edit-ctrl.enum';
export * from './lib/enum/en-medication-period.enum';