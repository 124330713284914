import { Injectable } from '@angular/core';
import { EnContentType, EnRequestType, error, LogService, routeRedisDeleteTenantKeys } from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { BasePageService } from './base-page.service';

@Injectable({
  providedIn: 'root'
})
export class RedisService {

  readonly urlRedisDeleteTenantKeys = routeRedisDeleteTenantKeys;
  constructor(
    private basepage: BasePageService,
    private log: LogService,
  ) {
  }

  deleteTenantKeys(): Observable<boolean> {
    try {
      const url = this.basepage.format(this.urlRedisDeleteTenantKeys);
      return this.basepage.baseDadosReplay(EnRequestType.Delete, url, {}, 2, EnContentType.Json)
        .pipe(
          flatMap(f => {
            return f;
          }),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteTenantKeys', error.message);
    }
    return of(null);
  }


}
