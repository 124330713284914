import { AsyncLocalStorageCacheService } from './async-local-storage-cache.service';
import { BasePageService } from './base-page.service';
import { WebService } from './web.service';
import { IForm, routeGetForm } from '@medlogic/shared/shared-interfaces';
import { ICadastro } from '@medlogic/shared/shared-interfaces';
import { IQueueItem } from '@medlogic/shared/shared-interfaces';
import { EnRequestType } from '@medlogic/shared/shared-interfaces';

import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';

import { Injectable } from '@angular/core';
import { catchError, map, filter, mergeMap, flatMap } from 'rxjs/operators';
import { forkJoin, iif } from 'rxjs';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { from } from 'rxjs';

@Injectable()
export class CadastroService extends UnsubscribeOnDestroyAdapter {
  // tslint:disable-next-line:max-line-length
  // urlCadastro = urlBase + "DynamicReport?pCadastroNo={0}&pOcorrenciaAtualNo={1}&pLstVariaveisRetorno={2}&pVariavelGrid={3}&pLstVariaveisGridRetorno={4}&pFiltroExtra={5}&FiltroDuplo=false";

  // TODO: API
  // urlCadastro = `Form/GetForm?formId={0}&listVariables={1}`;
  urlCadastro = `${routeGetForm}?formId={0}&listVariables={1}`;
  filterPeriod = '&DtInicio={0}&DtFim={1}';
  // tslint:disable-next-line:max-line-length
  urlGetCadastroComGrid = 'Form/GetDataRegister?CadastroNo={0}&OcorrenciaNo={1}&LstVariaveisRetorno={2}&VariavelGrid={3}&LstVariaveisGridRetorno={4}&VariavelMapa=&LstVariaveisMapa=&FiltroExtra={5}';
  dtInicial = '2015-01-01';
  dtFinal = '2050-01-01';

  method = 'setSaveCadastro';
  http: any;

  constructor(
    private basepage: BasePageService,
    private log: LogService,
    private ws: WebService,
    private glb: GlobalService,
    private asyncCache: AsyncLocalStorageCacheService
  ) {
    super();
  }

  /*
  * Buscar dados de um cadastro do GE
  * @param  {int} cadastroNo - Ex: 1
  * @param  {String} lstVariavel - Ex: "V_1234,V_1235,V_1236"
  * @param  {Function} callback - Ex: callback(valorRetorno)
  */
  getCadastro(cadastroNo: number, lstVariavel: string, dtBegin: Date = null, dtEnd: Date = null): Observable<any> {
    try {
      let urlGet = this.basepage.format(this.urlCadastro, cadastroNo, lstVariavel);
      if (dtBegin && dtEnd) {
        urlGet = this.basepage.format(`${urlGet}${this.filterPeriod}`, this.glb.dateToYYYYMMdd(dtBegin), this.glb.dateToYYYYMMdd(dtEnd));
      }
      return this.basepage.baseDados(EnRequestType.Get, urlGet, {}, 3).pipe(flatMap(res => res));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCadastro', error.message);
    }
    return of(null);
  }

  /*
  * Buscar dados de um cadastro do GE passando um filtro de uma ou mais variaveis
  * @param  {int} cadastroNo - Ex: 1
  * @param  {String} lstVariavel - Ex: "V_1234,V_1235,V_1236"
  * @param  {String} filtro - Ex: V_1234: [valor],V_1235: [valor]
  * @param  {Function} callback - Ex: callback(valorRetorno)
  * Atenção: Se fornecidas datas, as horas serão ignoradas. Se dtBegin for igual a dtEnd não trará registro algum.
  */
  getCadastroComFiltro(
    cadastroNo: number,
    lstVariavel: string,
    filtro: string,
    isFilterAnd: boolean,
    dtBegin: Date = null,
    dtEnd: Date = null): Observable<any> {
    try {
      const urlCadastroComFiltro = this.urlCadastro + '&filter={2}' + '&isFilterAnd={3}';
      let urlGet = this.basepage.format(
        urlCadastroComFiltro,
        cadastroNo,
        lstVariavel,
        filtro,
        isFilterAnd
      );
      if (dtBegin && dtEnd) {
        urlGet = this.basepage.format(`${urlGet}${this.filterPeriod}`, this.glb.dateToYYYYMMdd(dtBegin), this.glb.dateToYYYYMMdd(dtEnd));
      }
      return this.basepage.baseDados(EnRequestType.Get, urlGet, {}, 3).pipe(flatMap(res => {
        return res;
      }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCadastroComFiltro', error.message);
    }
    return of(null);
  }

  /*
  * Buscar dados de um cadastro do GE passando uma variavel de Grid e suas respecitivas variaveis
  * Caso o cadastro tenha mais de um item no Grid o resultado irá retorna duplicado, necessitando de agrupar depois
  * @param  {int} cadastroNo - Ex: 1
  * @param  {int} ocorrenciaNo - Ex: 1 para retornar um registro especifico e -1 para retornar todos os itens do cadastro
  * @param  {String} lstVariavel - Ex: "V_1234,V_1235,V_1236"
  * @param  {String} variavelGrid - Ex: "V_4312"
  * @param  {String} lstVariaveisGrid - Ex: "V_5478,V_1458,V_6542"
  * @param  {String} filtro - Ex: V_1234: [valor],V_1235: [valor]
  * @param  {Function} callback - Ex: callback(valorRetorno)
  */
  getCadastroComGridFiltro(
    cadastroNo: number,
    ocorrenciaNo: number,
    lstVariavel: string,
    variavelGrid: string,
    lstVariaveisGrid: string,
    filtro: string,
    token: string = '',
    dtBegin: Date = null,
    dtEnd: Date = null
  ): Observable<any> {
    try {
      let urlGet = this.basepage.format(
        this.urlGetCadastroComGrid,
        cadastroNo,
        ocorrenciaNo,
        lstVariavel,
        variavelGrid,
        lstVariaveisGrid,
        filtro
      );
      if (dtBegin && dtEnd) {
        urlGet = this.basepage.format(`${urlGet}${this.filterPeriod}`, this.glb.dateToYYYYMMdd(dtBegin), this.glb.dateToYYYYMMdd(dtEnd));
      }
      return this.basepage.baseDados(EnRequestType.Get, urlGet, {}, 3).pipe(flatMap(res => res));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCadastroComGridFiltro', error.message);
    }
    return of(null);
  }

  /* Ao invés de salvar de imediato, enfileira para posterior atualização. */
  saveToQueue(forms: IForm[], uno: number, ano: any, id?: any): Observable<any> {
    try {
      return this.save(forms, uno, ano, id, false);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'saveToQueue', error.message);
    }
    return of(null);
  }

  /* Resgata a lista de itens da fila do setCadastro. */
  getItemsFromQueue(): Observable<IQueueItem[]> {
    try {
      return this.asyncCache.getQueue(this.method);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getItemsFromQueue', error.message);
    }
    return of(null);
  }

  /* Atualiza (associa), ou cria. O número 27987 é correspondente ao cadastro de ESTOQUE DE MATERIAIS.
  * codigoVariavelNo: é importante especificar, pois, através desse campo é que será determinado um update ou criação de novo registro.
  * Foi definido um valor padrão que corresponde ao de estoque de materiais, mas todas as chamadas deveriam passar esse código adequadamente.
  * ATENÇÃO: id deve ser a OcorrenciaNo, que deve ser igual ao cadastro.
  * Em muitos casos o código do cadastro será coincidente com a ocorrenciano. No entanto,
  * se os dados subiram através de uma planilha de Excel, o código poderá divergir da ocorrenciaNo.
  */
  save(
    forms: IForm[],
    uno: number,
    ano: number,
    id: any,
    executeNow = true,
    codigoVariavelNo = 27987,
    notificarConclusaoSalvamento = false
  ): Observable<any> {
    try {
      let identificador: IForm;
      let setAll$: Observable<any>;
      if (!this.glb.isNullOrEmpty(id) && id > 0) {
        identificador = { VariavelNo: codigoVariavelNo, ValorDado: id.toString() } as IForm;
        setAll$ = this.setAll(ano, uno, identificador, forms, executeNow);
      } else {
        const guid = this.glb.getGUID();
        identificador = { VariavelNo: codigoVariavelNo, ValorDado: guid } as IForm;
        const finded = forms.find((f) => f.VariavelNo === codigoVariavelNo);
        if (!finded) {
          // Se a propriedade não é criada, o código não é preenchido pelo serviço.
          forms.push({ VariavelNo: codigoVariavelNo, ValorDado: guid } as IForm);
        }
        setAll$ = this.setAll(ano, uno, identificador, forms, executeNow);
      }
      return setAll$.pipe(
        mergeMap((ono: number) =>
          iif(
            () => notificarConclusaoSalvamento && ono > 0,
            this.notificarConclusaoSalvamento$(ano, ono),
            of(ono)
          )
        )
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  /* No campo identificador, é possível informar a variável e o valor que representam
  * o identificador único. Desta forma, se o registro for encontrado, atualiza, senão cria.
  * executeNow: executa o setAll se true. Caso contrário, enfileira no cache para posterior execução.
  */
  setAll(
    ano: number,
    uno: number,
    identificador: IForm,
    forms: IForm[],
    executeNow = true
  ): Observable<any> {
    try {
      const cadastro = {
        Indice: 1,
        CampoIdentificador: identificador,
        LstItensCadastro: forms
      } as ICadastro;
      if (executeNow) {
        // Executa a chamada no serviço
        return this.executeSetAll(ano, uno, cadastro);
      } else {
        // enfileirar a chamada
        const item = {
          key: identificador?.ValorDado,
          cadastro,
          ano,
          uno
        } as IQueueItem;
        return this.asyncCache.queueItem(this.method, item);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setAll', error.message);
    }
    return of(null);
  }

  /* Execução da chamada, submetendo o item ao serviço. */
  protected executeSetAll<T>(ano: number, uno: number, cadastro: ICadastro | ICadastro[]): Observable<T> {
    try {
      const arrCadastro = this.glb.alwaysReturnArray(cadastro);
      const result = this.ws.connect<T>(this.method, [
        { name: 'CadastroNo', value: ano },
        { name: 'UsuarioNo', value: uno },
        { name: 'LstDados', value: JSON.stringify(arrCadastro) }
      ]);
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'executeSetAll', error.message);
    }
    return of(null);
  }

  /* Executa a fila de setSaveCadatro.
  * Também removerá o item da fila, caso o retorno seja positivo.
  * Para cada item executado e removido da fila, emitirá um observer.
  */
  executeQueue(): Observable<any> {
    try {
      return this.asyncCache.getQueue(this.method)
        .pipe(
          mergeMap(m => from(m)), // Necessário, pois a emissão é única com um array contendo todos os itens.
          this.postItem(),
          this.popUp(),
          catchError((err, res) => {
            console.log(err.message);
            return res;
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'executeQueue', error.message);
    }
    return of(null);
  }

  /* Salva o item através do serviço e retorna o Id do item criado, mas também a key.  */
  postItem = () => mergeMap((e: any) => {
    try {
      if (e) {
        const setAll = this.executeSetAll<number>(e.ano, e.uno, e.cadastro);
        // Chamada em paralelo para permitir tando o retorno do Id criado, quanto da key.
        return forkJoin([setAll, of(e.key)]);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'executeQueue.forEach', error.message);
    }
    return of(null);
  })

  /* Enfileira as chamadas para exclusão da fila. Necessário, pois, a tentativa de abertura simultânea do objeto gerará falha. */
  popUp = () => mergeMap(([insertedId, key]) => {
    return (insertedId && insertedId > 0) ?
      this.asyncCache.queueClearItem(this.method, key)
      : of(null);
  })

  /* Atualiza o contador de pendências. */
  updatePending(): void {
    try {
      this.asyncCache.updatePending(this.method);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updatePending', error.message);
    }
  }

  /* Método utilizado para popular uma lista com os itens ativos.
  * anotherProperties: lista com nomes de outras propriedades que se deseja resgatar.
  */
  loadArray(
    provider: Observable<any>,
    propLabel: string = 'titulo',
    propValue: string = 'codigo',
    propEnabled: string = 'habilitado',
    anotherProperties: string[] = null
  ): Observable<any> {
    try {
      return provider.pipe(
        filter((f) => f[propEnabled]),
        map((m) => {
          const obj = {
            label: m[propLabel] || '',
            value: m[propValue] || ''
          };
          if (anotherProperties) {
            anotherProperties.forEach((prop) => {
              if (m.hasOwnProperty(prop)) {
                obj[prop] = m[prop] || '';
              }
            });
          }
          return obj;
        }),
        catchError((e, item) => {
          console.log('CadastroService.loadArray', e);
          return item;
        })
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
    return of(null);
  }

  /* Exclui um item no cadastro. Atenção, como retorna Observable é necessário dar um subscribe para que seja executado. */
  deleteCadastro(cadastroNo: number, ocorrenciaNo: number): Observable<number> {
    try {
      const method = 'ExecucaoTarefa_DeleteCadastro';
      const result = this.ws.connect<number>(method, [
        { name: 'OcorrenciaNo', value: ocorrenciaNo },
        { name: 'AtividadeNo', value: cadastroNo }
      ]);
      // Necessário notificar o serviço sobre a finalização da exclusão para geração do XML
      return result.pipe(
        mergeMap(m => this.notificarConclusaoSalvamento$(cadastroNo, ocorrenciaNo)),
        catchError((error) => of(null))
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteCadastro', error.message);
    }
  }

  /* Necessário para geração do XML.
  * Esse método estava, originalmente em Dado-dal. Verificar se agora os formulários,
  * não estão chamando esse método pela segunda vez.
  */
  notificarConclusaoSalvamento$ = (cadastroNo: number, ocorrenciaNo: number): Observable<number> => {
    try {
      const method = 'CadastroNotificarConclusaoSalvamento';
      return this.ws
        .connect(method, [
          { name: 'CadastroNo', value: cadastroNo },
          { name: 'OcorrenciaNo', value: ocorrenciaNo }
        ])
        .pipe(map((success) => {
          return success ? ocorrenciaNo : -1;
        }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'notificarConclusaoSalvamento$', error.message);
    }
    return of(null);
  }


}
