import { GlobalService, IMedication, ILog, IAppState } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { withLatestFrom, map } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LogService,
} from '@medlogic/shared/shared-interfaces';
import { logFail, loadLogs, loadLogsSuccess } from './log.actions';


@Injectable()
export class LogEffects {

  constructor(
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private store: Store<IAppState>,
  ) { }

  // loadLogs$ = createEffect(() => this.actions$
  //   .pipe(
  //     ofType(loadLogs),
  //     withLatestFrom(this.store),
  //     mergeMap(([never, state]) => {
  //       return this.logSrv.getAll(state?.tenant?.selectedTenant?.cadPacienteNo)
  //         .pipe(
  //           toArray(),
  //           mergeMap(logs => {
  //             // FIXME: ATENÇÃO: Esse cálculo está errado, pois, precisa considerar as medicações que se repetem em horários diferentes
  //             return this.intervMedSrv.getMedicationCount(state?.tenant?.selectedTenant?.cadIntervecoesMedicamentosNo)
  //               .pipe(
  //                 map(count => {
  //                   return logs.map(m => ({ ...m, calcMedicationsCount: count[+m.codigo] } as ILog));
  //                 }));
  //           }),
  //           switchMap((logs: ILog[]) => [
  //             logs ? loadLogsSuccess({ logs }) : logFail(null),
  //             setIsLoading({ isLoading: false })
  //           ]),
  //           catchError((e: any) => {
  //             console.log(e);
  //             return of(logFail(null));
  //           })
  //         );
  //     })
  //   )
  // );


}
