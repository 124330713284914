import { IMedicationState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { IInterventionMedication, IMedication } from '@medlogic/shared/shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as MedicationActions from './medication.actions';

export const medicationsFeatureKey = 'medication';

export const adapterMedication: EntityAdapter<IMedication> = createEntityAdapter<IMedication>({
  selectId: instance => getMedicationId(instance) // Id pode não existir no início
  // selectId: instance => `${instance.medicationId}_${instance.prescribedTime}` // Id pode não existir no início
  // selectId: instance => instance.medicationId // TODO: Checar se é necessário combinar o prescribed time devido a medicações repetidas no mesmo horário
});

export const getMedicationId = (medication: IMedication | IInterventionMedication): string => {
  try {
    // return `${medication.medicationId}_${medication.prescribedTime}`;
    // return `${medication?.medicationName}_${medication?.dosage}_${medication?.prescribedTime}`;
    return (medication?.guid /*Sistema*/) ||
      (`${(medication as IMedication).medicationId}_${(medication as IMedication).prescribedTime}` /* PWA */);
  } catch (error) {
    console.log('medication.reducer', 'getMedicationId', error.message);
  }
  return null;
}

export const initialStateMedication: IMedicationState = adapterMedication.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedId: null,
  numOfDaysToCheckOcurrency: 10,
  confirmItem: null,
  isSaving: false,
  rawMedications: null,
  prescriptionId: null,
  selectedMedication: null,
  allMedications: null,
  isLoading: false
});

export const reducer = createReducer(
  initialStateMedication,
  on(MedicationActions.addMedication,
    (state, action) => adapterMedication.addOne(action.medication, state)
  ),
  on(MedicationActions.loadMedications,
    (state, action) => ({ ...state, numOfDaysToCheckOcurrency: action.numOfDaysToCheckOcurrency })
  ),
  on(MedicationActions.loadRawMedications,
    (state, action) => ({ ...state })
  ),
  on(MedicationActions.loadMedicationsBySelectedPatient,
    (state, action) => ({ ...state, numOfDaysToCheckOcurrency: action.numOfDaysToCheckOcurrency })
  ),
  on(MedicationActions.loadMedicationsByPrescription,
    (state, action) => ({ ...state, allMedications: null, selectedMedication: null, prescriptionId: action.prescriptionId })
  ),
  on(MedicationActions.upsertMedication,
    (state, action) => adapterMedication.upsertOne(action.medication, state)
  ),
  on(MedicationActions.addMedications,
    // AddMany é problemático, pois, caso haje medicações excluídas, elas permaneceriam
    // (state, action) => adapterMedication.addMany(action.medications, state)
    (state, action) => adapterMedication.setAll(action.medications, state)
  ),
  // on(MedicationActions.addMedicationsandInsertStock,
  //   (state, action) => adapterMedication.addOne(action.medication, state)
  // ),
  on(MedicationActions.upsertMedications,
    (state, action) => adapterMedication.upsertMany(action.medications, state)
  ),
  on(MedicationActions.updateMedication,
    (state, action) => adapterMedication.updateOne(action.medication, state)
  ),
  on(MedicationActions.updateMedications,
    (state, action) => adapterMedication.updateMany(action.medications, state)
  ),
  on(MedicationActions.deleteMedication,
    (state, action) => adapterMedication.removeOne(action?.medication?.guid, state)
  ),
  on(MedicationActions.deleteMedications,
    (state, action) => adapterMedication.removeMany(action?.medications?.map(m => m.guid), state)
  ),
  on(MedicationActions.setMedicationsWasChanged,
    (state, { wasChanged }) => adapterMedication.map(m => ({ ...m, wasChanged }), state)
  ),
  on(MedicationActions.loadMedicationsSuccess,
    (state, action) => adapterMedication.upsertMany(action.medications, state)
  ),
  on(MedicationActions.loadRawMedicationsSuccess,
    (state, action) => ({ ...state, rawMedications: action?.rawMedications })
  ),
  on(MedicationActions.medicationFail,
    (state, action) => ({
      ...state,
      medications: null,
      error: action?.error
    })
  ),
  on(MedicationActions.rawMedicationFail,
    (state, action) => ({
      ...state,
      rawMedications: null,
      error: action?.error
    })
  ),
  on(MedicationActions.confirmMedication,
    (state, action) => adapterMedication.upsertOne({ ...action.medication, isSaving: true }, { ...state, confirmItem: action }) // { medication: action?.medication, patient: action?.patient, observation: action?.observation, updateStock: action?.updateStock })
  ),
  on(MedicationActions.clearMedications,
    state => initialStateMedication
  ),
  on(MedicationActions.saveMedicationSuccess,
    (state, action) => ({
      ...state,
      changeItem: null,
      error: null
    })
  ),
  on(MedicationActions.setIsLoadingMedication,
    (state, action) => ({
      ...state,
      isLoading: action?.isLoading
    })
  )
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterMedication.getSelectors();
