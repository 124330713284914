import { CadastroService } from '@medlogic/shared/shared-data-access';
import { ExecucaoTarefaDAL } from '@medlogic/shared/geform';
import { Injectable } from '@angular/core';
import { MedicationCheckinService } from './medication-checkin.service';
import { Observable, of } from 'rxjs';
import { IForm, IMedication, IMedicationCheckin, IPatient, GlobalService, LogService } from '@medlogic/shared/shared-interfaces';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Injectable()
export class MedicationCheckinCustomService extends MedicationCheckinService {

  constructor(
    http: HttpClient,
    cadastroSrv: CadastroService,
    glb: GlobalService,
    log: LogService,
    protected taskSrv: ExecucaoTarefaDAL
  ) {
    super(http, cadastroSrv, glb, log);
  }

  /* Retorna todos os itens do histórico de compras através do id do item. */
  getByCodigoPaciente(ano: number, codigoPaciente: number, dtStart: Date = null, dtEnd: Date = null): Observable<IMedicationCheckin> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filter = `V_29828:${codigoPaciente}`;
      return this.getFiltered(this.cadastroNo, filter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByCodigoPaciente', error.message);
    }
    return of(null);
  }

  /* Insere ou atualiza o item.
     * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
     * Se houver uma instrução de executeNow irá checar a conexão com a Internet.
     * Caso haja, irá tentar salvar. Se não conseguir, irá enfileirar a chamada.
     */
  save(
    ano: number,
    medicationCheckin: IMedicationCheckin,
    patient: IPatient,
    medication: IMedication,
    uno: number,
    id?: number,
    executeNow: boolean = true
  ): Observable<any> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(medicationCheckin).filter(
        (f) => !this.glb.IsNullOrEmpty(f.ValorDado)
      );
      const key = id || this.generateKey(patient.codigo, medication, medicationCheckin.dataCheckin);
      const saveToQueue$ = this.cadastroSrv.saveToQueue(forms, uno, this.cadastroNo, key);
      const save$ = this.cadastroSrv.save(forms, uno, this.cadastroNo, key);
      if (!executeNow) {
        return saveToQueue$;
      } else if (navigator.onLine) {
        return save$.pipe(
          mergeMap(wasSaved => {
            if (!wasSaved) {
              // Como não conseguiu salvar
              return saveToQueue$;
            }
            return of(wasSaved);
          }),
          catchError((err, obs) => {
            // Como deu erro, tenta salvar na fila.
            return saveToQueue$;
          })
        );
      } else {
        return saveToQueue$;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
  }

  deleteById(cno: number, ono: number): Observable<boolean> {
    try {
      this.taskSrv.deleteCadastro(ono, cno);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteById', error.message);
    }
    return of(null);
  }

  /* Mapeio para o objeto necessário para salvar. */
  mapToIMedicationCheckin(medication: IMedication, patient: IPatient, observation: string, userName: string): IMedicationCheckin {
    try {
      // Atenção: para compor o ID é necessário considerar a data e hora prescrita e não do checkin, senão não será possível correlacionar corretamente como o item prescrito.
      const datePrescricao = moment(medication?.medicationDate); // .format("DD/MM/YYYY");
      const horaPrescricao = medication?.prescribedTime;
      const horaCheckin = moment(medication?.medicationDate).format('HH:mm');
      const titulo = this.generateMedicationTitle(patient, medication);
      const key = this.generateKey(patient?.codigo, medication, medication?.medicationDate);
      const medicationCheckin = {
        cascataCheckIDClienteIDMedicamentoDataAtual: key,
        identificacao: key,
        horaPrescricao,
        diaPrescricao: datePrescricao?.day().toString(),
        dataCheckin: medication?.medicationDate,
        horaCheckin,
        status: medication?.took ? 'S' : 'NJ',
        // O texto Tomou, que é necessário para que o campo seja considerado true, é redefinido na imedication-checkin
        observacoes: observation,
        statusmedicacao: titulo,
        // codigo: -1, O código não pode ser fornecido para que seja calculado automaticamente no serviço
        titulo,
        habilitado: true,
        codigoMedicamentoPaciente: medication?.medicationId + '_' + patient?.nomeHospede,
        profissional: userName,
        codigoPaciente: patient?.codigo,
        Id_Paciente: patient?.codigo,
        Id_Medicamento: medication?.medicationId?.toString(),
        IdMedicamento_IdPaciente: `${medication?.medicationId}_${patient?.codigo}`,
        tomou: medication?.took
      } as IMedicationCheckin;
      return medicationCheckin;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToIMedicationCheckin', error.message);
      return null;
    }
  }

}
