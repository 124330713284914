<!-- Menu Top -->
<header *ngIf="showMenu" class="no-print">

  <mat-toolbar color="4CAF50">
    <!-- *ngIf="isMobile" -->

    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu">

      <div class="menu-title">
        <mat-icon>person</mat-icon>
        <span>{{ userName }}</span>
      </div>

      <!-- <button class="btn-menu" mat-menu-item (click)="onMedication($event)" *ngIf="hasMedication">
        <mat-icon>alarm_on</mat-icon>
        <span>Medicação</span>
      </button> -->

      <!-- <button class="btn-menu" mat-menu-item (click)="onKitchen($event)" *ngIf="hasKitchen">
        <mat-icon>room_service</mat-icon>
        <span>Cozinha</span>
      </button> -->
      <!-- <mat-button-toggle class="btn-menu" #mobileToogle [checked]="pv?.isOffLineMode" (click)="offLineClick(pv?.isOffLineMode)">
        <mat-icon>sync</mat-icon>
        <span>Modo Offline</span>
      </mat-button-toggle> -->

      <!-- <button class="btn-menu" mat-menu-item (click)="onSync($event)" [disabled]="!isBtnSyncEnabled"
        *ngIf="hasMedication">
        <mat-icon>sync</mat-icon>
        <span>Sincronizar</span>
      </button> -->

      <!-- <button mat-raised-button #mobileToogle (click)="forceMobile(true)">
        <mat-icon>important_devices</mat-icon>
        <span>Comportamento Desktop</span>
      </button> -->

      <!-- <mat-button-toggle #mobileToogle class="btn-menu" [checked]="!cnf.isForceMobile"
        (click)="forceMobile(cnf.isForceMobile)">
        <mat-icon>important_devices</mat-icon>
        <span>Comportamento Desktop</span>
      </mat-button-toggle> -->

      <button class="btn-menu" mat-menu-item (click)="onFullScreenClick($event)">
        <mat-icon>fullscreen</mat-icon>
        <span>Tela cheia</span>
      </button>

      <button class="btn-menu" mat-menu-item (click)="onLogoff($event)">
        <mat-icon>exit_to_app</mat-icon>
        <span>Sair</span>
      </button>

    </mat-menu>

    <button mat-button class="btn-pending btn-menu" (click)="onSync($event)" [disabled]="!isBtnSyncEnabled">
      <!-- <mat-icon>assignment_return</mat-icon> -->
      <span>checkins</span>
      <span class="label label-warning">{{ pending }}</span>
    </button>

    <button mat-button class="btn-pending btn-menu" (click)="onSyncStock($event)" *ngLet="pendingStock$ | async as pendingStock">
      <span>estoque</span>
      <span class="label label-warning">{{ pendingStock }}</span>
    </button>

    <!-- <span class="example-spacer"></span> -->
    <!-- <mat-icon class="example-icon">verified_user</mat-icon> -->
    <!-- salvo -->
    <!-- <span class="label label-warning">{{ saved }}</span> -->

  </mat-toolbar>

</header>

<div class="ibox-content text-center p-sm" [class.isMobile]="isMobile">
  <!-- Cards -->
  <router-outlet></router-outlet>
</div>