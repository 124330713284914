import { Component, OnInit, Input } from '@angular/core';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import { LogService, GlobalService } from '@medlogic/shared/shared-interfaces';
import { swapImagePerson } from '../../service/pwa-util';

@Component({
  selector: 'ml-patient-header',
  templateUrl: './patient-header.component.html',
  styleUrls: ['./patient-header.component.css']
})
export class PatientHeaderComponent implements OnInit {

  @Input() patient: IPatient;
  @Input() showMiniImg: boolean;

  constructor(
    private log: LogService,
    private glb: GlobalService
  ) { }

  ngOnInit() {
  }

  /* Retorna a idade baseado na data de nascimento. */
  getAge(birthDate: Date): number {
    try {
      if (birthDate !== undefined) {
        return this.glb.GetYearsOld(this.glb.getTypedValue(birthDate).value);
      } else {
        return 0;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAge', error.message);
    }
  }

  swapImage(img: HTMLImageElement, patient: IPatient): void {
    try {
      swapImagePerson(img, patient?.sexo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'swapImage', error.message);
    }
  }


}
